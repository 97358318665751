import React from 'react'

const Contactus = () => {
  return (
    <div>
      <section
        className="breadcrumbs"
        style={{
          backgroundImage:
            "url(wp-content/uploads/elementor/img/contactusback.png)"
        }}
      >
        <div className="breadcrumb-sm-images">
          <div className="magnetic-wrap">
            <div className="magnetic-wrap">
              <div className="inner-banner-2 magnetic-item">

              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1>Contact </h1>
                  <div className="breadcrumb-list">
                    <a href="#">Home</a>
                    <svg
                      width={16}
                      height={9}
                      viewBox="0 0 16 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.0919919 4.87815C0.144379 4.95392 0.237065 5.05915 0.297512 5.10545C0.410347 5.18964 0.454675 5.19385 1.81675 5.19385C3.17883 5.19385 3.22315 5.18964 3.33599 5.10545C3.5536 4.93708 3.63822 4.75608 3.62211 4.49511C3.60196 4.22151 3.49315 4.0363 3.29166 3.93528C3.1748 3.87635 2.96928 3.86793 1.80869 3.86793C0.494973 3.86793 0.462734 3.87214 0.309601 3.96474C0.0113956 4.14153 -0.0933793 4.59192 0.0919919 4.87815Z"
                        fill="white"
                      />
                      <path
                        d="M4.74664 4.75221C4.80306 4.89533 4.93201 5.05107 5.0529 5.12263C5.1738 5.18998 5.49215 5.19419 9.37286 5.21523L13.5639 5.23628L12.0446 6.57904C11.0573 7.45036 10.5012 7.97231 10.4609 8.0607C10.3239 8.36377 10.4488 8.77207 10.7228 8.9236C10.88 9.01621 11.1822 9.02462 11.3152 8.94886C11.3636 8.91939 12.4073 8.01019 13.6404 6.9242C15.1476 5.59407 15.8971 4.90375 15.9415 4.81535C16.0623 4.54175 15.9938 4.24289 15.7561 4.00717C15.3571 3.61992 11.287 0.105177 11.1782 0.0546658C11.0049 -0.0253103 10.8558 -0.0168913 10.6584 0.0757124C10.34 0.227247 10.203 0.681847 10.3763 0.989124C10.4166 1.06068 11.146 1.72575 11.9963 2.46237C12.8425 3.2032 13.5397 3.81775 13.5397 3.83459C13.5437 3.85564 11.6618 3.86827 9.35674 3.86827L5.1738 3.86827L5.04082 3.96087C4.83932 4.10819 4.75067 4.25973 4.73455 4.48703C4.72649 4.59647 4.73052 4.71854 4.74664 4.75221Z"
                        fill="white"
                      />
                    </svg>
                    Contact{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page-wrapper">
        <div className="page-content-wrapper">
          <div
            data-elementor-type="wp-page"
            data-elementor-id={27}
            className="elementor elementor-27"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-2659f0c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="2659f0c"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d3fb02f"
                  data-id="d3fb02f"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-549d57a elementor-widget elementor-widget-softconic_contact"
                      data-id="549d57a"
                      data-element_type="widget"
                      data-widget_type="softconic_contact.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="contact-page-wrap">
                          <div className="container">
                            <div className="row g-lg-4 gy-5">
                              <div className="col-lg-6">
                                <div className="contact-content">
                                  <span> CONTACT WITH US</span>
                                  <h2>We’re Here to Help You</h2>
                                  <p className="head-desc">
                                    Got a project in mind? We’d love to hear about it. Take five minutes to fill out our project form so that we can get to know you and understand your project.
                                  </p>
                                  <div className="informations">
                                    <div className="single-info">
                                      <div className="icon">
                                        <i
                                          aria-hidden="true"
                                          className="fas fa-map-marker-alt"
                                        />{" "}
                                      </div>
                                      <div className="info">
                                        <p>

                                          S2 Integrators LLC 1000 Park Wood Circle SE, Suite #900, Atlanta, GA, 30339
                                        </p>
                                      </div>
                                    </div>
                                    <div className="single-info phone-new">
                                      <div className="icon">
                                        <i
                                          aria-hidden="true"
                                          className="fas fa-phone-alt"
                                        />{" "}
                                      </div>
                                      <div className="info">
                                        <a href="tel:+1 (678) 784 4155">

                                          +1 (678) 784 4155
                                        </a>

                                      </div>
                                    </div>
                                    <div className="single-info">
                                      <div className="icon">
                                        <i
                                          aria-hidden="true"
                                          className="far fa-envelope"
                                        />{" "}
                                      </div>
                                      <div className="info email-in">
                                        <a href="mailto: info@qcodesinfotech.com">
                                          info@qcodesinfotech.com
                                        </a>
                                      
                                      </div>
                                    </div>
                                  </div>
                                  <div className="follow-area">
                                    <h5 className="blog-widget-title">Follow Us</h5>
                                    <p className="para">
                                      Follow us on Social Network
                                    </p>
                                    <div className="blog-widget-body">
                                      <ul className="follow-list d-flex flex-row align-items-start gap-4">
                                        <li>
                                          <a href="https://www.facebook.com/">
                                            <i className="bx bxl-facebook" />
                                          </a>
                                        </li>
                                        <li>
                                          <a href="https://www.twitter.com/">
                                            <i className="bx bxl-twitter" />
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#">
                                            <i className="bx bxl-pinterest" />
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#">
                                            <i className="bx bxl-instagram" />
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="contact-form-wrap">
                                  <div className="form-tltle">
                                    <h5>Make a Free Consulting</h5>
                                  </div>
                                  <div className="contact-form">
                                    <div
                                      className="wpcf7 js"
                                      id="wpcf7-f449-p27-o1"
                                      lang="en-US"
                                      dir="ltr"
                                    >
                                      <div className="screen-reader-response">
                                        <p
                                          role="status"
                                          aria-live="polite"
                                          aria-atomic="true"
                                        />{" "}
                                        <ul />
                                      </div>
                                      <form
                                        action="/contact/#wpcf7-f449-p27-o1"
                                        method="post"
                                        className="wpcf7-form init"
                                        aria-label="Contact form"
                                        noValidate="novalidate"
                                        data-status="init"
                                      >
                                        <div style={{ display: "none" }}>
                                          <input
                                            type="hidden"
                                            name="_wpcf7"
                                            defaultValue={449}
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_version"
                                            defaultValue="5.7.7"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_locale"
                                            defaultValue="en_US"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_unit_tag"
                                            defaultValue="wpcf7-f449-p27-o1"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_container_post"
                                            defaultValue={27}
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_posted_data_hash"
                                            defaultValue=""
                                          />
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6 mb-20">
                                            <div className="form-inner">
                                              <label>first name</label>
                                              <span
                                                className="wpcf7-form-control-wrap"
                                                data-name="f-name"
                                              >
                                                <input
                                                  size={40}
                                                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                  aria-required="true"
                                                  aria-invalid="false"
                                                  defaultValue=""
                                                  type="text"
                                                  name="f-name"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-md-6 mb-20">
                                            <div className="form-inner">
                                              <label>Last name</label>
                                              <span
                                                className="wpcf7-form-control-wrap"
                                                data-name="l-name"
                                              >
                                                <input
                                                  size={40}
                                                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                  aria-required="true"
                                                  aria-invalid="false"
                                                  defaultValue=""
                                                  type="text"
                                                  name="l-name"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 mb-20">
                                            <div className="form-inner">
                                              <label>Company/Organization</label>
                                              <span
                                                className="wpcf7-form-control-wrap"
                                                data-name="company-name"
                                              >
                                                <input
                                                  size={40}
                                                  className="wpcf7-form-control wpcf7-text"
                                                  aria-invalid="false"
                                                  defaultValue=""
                                                  type="text"
                                                  name="company-name"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 mb-20">
                                            <div className="form-inner">
                                              <label>Email</label>
                                              <span
                                                className="wpcf7-form-control-wrap"
                                                data-name="email-704"
                                              >
                                                <input
                                                  size={40}
                                                  className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                  aria-required="true"
                                                  aria-invalid="false"
                                                  defaultValue=""
                                                  type="email"
                                                  name="email-704"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 mb-20">
                                            <div className="form-inner">
                                              <label>Phone</label>
                                              <span
                                                className="wpcf7-form-control-wrap"
                                                data-name="phn-number"
                                              >
                                                <input
                                                  size={40}
                                                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                  aria-required="true"
                                                  aria-invalid="false"
                                                  defaultValue=""
                                                  type="text"
                                                  name="phn-number"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 mb-20">
                                            <div className="form-inner">
                                              <label>Message</label>
                                              <span
                                                className="wpcf7-form-control-wrap"
                                                data-name="message"
                                              >
                                                <textarea
                                                  cols={40}
                                                  rows={10}
                                                  className="wpcf7-form-control wpcf7-textarea"
                                                  aria-invalid="false"
                                                  name="message"
                                                  defaultValue={""}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-lg-12">
                                            <div className="form-inner">
                                              <button
                                                className="primary-btn3"
                                                type="submit"
                                              >
                                                Submit
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="wpcf7-response-output"
                                          aria-hidden="true"
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <img
              src="/wp-content/uploads/elementor/img/viewmap.png"
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contactus
