import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Define your goals and objectives:",
        content:
            "Determine the purpose of your website and what you want to achieve with it. Identify your target audience, key features, and functionality requirements.",
    },
    {
        heading: "Plan and research:",
        content:
            "Conduct thorough research on your target audience, competitors, and industry trends. Create a sitemap to outline the structure and navigation of your website. Plan the content and gather any necessary resources such as images, videos, and text.",
    },
    {
        heading: "Choose a development platform:",
        content:
            "Select a suitable platform for building your website. Some popular options include WordPress, Drupal, Joomla, Shopify, or custom development using HTML, CSS, and JavaScript.",
    },
    {
        heading: "Design the user interface (UI):",
        content:
            "Create wireframes or mockups to visualize the layout and design of your website. Consider the user experience (UX) by ensuring intuitive navigation, responsive design for different devices, and accessibility for all users.",
    },
    {
        heading: "Develop the website:",
        content:
            "Start implementing the design by coding the website using the chosen platform or programming languages. This involves writing HTML/CSS for the structure and styling, JavaScript for interactivity, and backend development for dynamic functionality such as forms, databases, and content management systems (CMS).",
    },
    {
        heading: "Content creation:",
        content:
            "Write and optimize the website content to align with your objectives and target audience. Ensure that the content is engaging, informative, and properly formatted for search engine optimization (SEO) purposes.",
    },
    {
        heading: "Testing and debugging:",
        content:
            "Thoroughly test the website across different browsers, devices, and screen sizes to ensure it functions properly and displays correctly. Identify and fix any bugs, broken links, or performance issues.",
    },
    {
        heading: "Launch:",
        content:
            "Once the website is fully tested and approved, it's time to deploy it to a web server and make it accessible to the public. Configure domain and hosting settings, set up SSL certificates for secure connections, and ensure proper backup mechanisms are in place.",
    },
    {
        heading: "Website maintenance and updates:",
        content:
            "Regularly monitor and maintain your website by applying security updates, fixing any issues that arise, and keeping the content fresh and up to date. This includes updating plugins, themes, and any other components used in the website.",
    },
    {
        heading: "Drive traffic and analyze performance:",
        content:
            "Implement marketing strategies such as search engine optimization (SEO), social media marketing, and content marketing to attract visitors to your website. Analyze website performance using tools like Google Analytics to track user behavior, conversions, and make data-driven improvements.",
    },
];

var features = [
    {
        heading: "Responsive Design:",
        content:
            "A responsive website adapts to different screen sizes and devices, ensuring an optimal user experience on desktops, laptops, tablets, and smartphones.",
    },
    {
        heading: "User-Friendly Interface:",
        content:
            "The website should have an intuitive and user-friendly interface, making it easy for visitors to navigate, find information, and interact with the site.",
    },
    {
        heading: "Clear Navigation:",
        content:
            "A well-structured and organized navigation menu helps users find their way around the website, accessing different pages and sections without confusion.",
    },
    {
        heading: "Compelling Visual Design:",
        content:
            "The website should have an attractive and visually appealing design that aligns with the brand identity and captures the attention of visitors. It includes the use of color schemes, typography, images, and multimedia elements.",
    },
    {
        heading: "Engaging Content:",
        content:
            "High-quality, informative, and engaging content is crucial for a website. It should be well-written, easy to read, and relevant to the target audience, providing value and encouraging visitors to stay and explore further.",
    },
    {
        heading: "Interactive Elements:",
        content:
            "Incorporating interactive elements like forms, surveys, quizzes, comment sections, social media integration, and user-generated content encourages visitor engagement, participation, and interaction with the website.",
    },
    {
        heading: "Fast Load Times:",
        content:
            "Optimizing the website's performance and ensuring fast load times is essential for user experience and search engine optimization (SEO). This can be achieved through techniques like image compression, code optimization, caching, and efficient hosting.",
    },
];
const SnowflakePartnership = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Snowflake Partnership",
                    desc: "S2Integrators has partnered with Snowflake to provide data migration and data architecture consulting services to its clients"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Snowflake Partnership",
                        desc: "In recent years, Snowflake has emerged as a leading cloud data platform that facilitates real-time data access and seamless integration with leading data analytics and BI tools. S2 Integrators has partnered with Snowflake to deliver industry-leading cloud data warehousing for our clients. A cloud-agnostic data-sharing layer allows you to choose your desired cloud hosting platform, such as our partners AWS and Microsoft Azure. Native integrations are available with data analytics and business intelligence platforms. S2Integrators has partnered with Snowflake to provide data migration and data architecture consulting services to its clients. This allows us to design and implement data warehouses that are future-proof, giving your enterprise the confidence and certainty needed to expand its operations.",
                        image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "SAP Ariba Buying",
                            "SAP Ariba Sourcing",
                            "SAP Ariba Contracts",
                            "Ariba Network",
                            "SAP Ariba Supplier Risk",
                            "SAP Ariba Spend Analysis"
                        ]
                    },
                    sessionTwo: {
                        title: "Snowflake Partnership",
                        desc: "S2 Microsoft technology services are fortifying lean, mean, and highly scalable business solutions. They are centered on the best possible use of technology and a penetrating direct business strategy spearhead. We power rapid digital businesses with completely enhanced customer experiences that are leveraging the right Microsoft products. Build fast working productivity boosters for beneficiaries and significant business stakeholders. Stumble into a higher trajectory of growth with great solutions for CRM, supply chain, core development, and business process management (BPM) with Microsoft technologies.",
                        image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }

                }}
            />

            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Data warehouse modernization",
                        desc: "Say goodbye to legacy data warehouses and hello to a modern data platform that unifies all your data and delivers valuable insights. We’ll help you build a business modernization framework and deploy solutions on top of Snowflake—minus the headache of traditionally complex data solutions."
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Snowflake Implementation Planning",
                        desc: "No two businesses are the same, and our Snowflake Implementation Planning services understand this. Our Snowflake experts can plan and design a data warehousing solution that readily accommodates your existing operations with the scalability and resilience to support your future business growth. From choosing the right cloud-hosting platform to driving data analytics and BI integration, we can help you find the optimal solution for your specific business needs."
                    },
                }}
            />

            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Migrating from SAP BW, Teradata",
                        desc: "Legacy data warehousing systems lack the performance and integrations with newer cloud-native solutions. They are also more costly, with hefty annual license fees. Snowflake operates on serverless infrastructure, billing you for the resources you use while also being scalable. S2Integrators experts can help you migrate from these legacy platforms to Snowflake to achieve better performance and reliability and to scale operations in the cloud."
                    },
                }}
            />
            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default SnowflakePartnership