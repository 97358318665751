import React from 'react'

var process = [
    {
        heading: "Define your goals and objectives:",
        content:
            "Determine the purpose of your website and what you want to achieve with it. Identify your target audience, key features, and functionality requirements.",
    },
    {
        heading: "Plan and research:",
        content:
            "Conduct thorough research on your target audience, competitors, and industry trends. Create a sitemap to outline the structure and navigation of your website. Plan the content and gather any necessary resources such as images, videos, and text.",
    },
    {
        heading: "Choose a development platform:",
        content:
            "Select a suitable platform for building your website. Some popular options include WordPress, Drupal, Joomla, Shopify, or custom development using HTML, CSS, and JavaScript.",
    },
    {
        heading: "Design the user interface (UI):",
        content:
            "Create wireframes or mockups to visualize the layout and design of your website. Consider the user experience (UX) by ensuring intuitive navigation, responsive design for different devices, and accessibility for all users.",
    },
    {
        heading: "Develop the website:",
        content:
            "Start implementing the design by coding the website using the chosen platform or programming languages. This involves writing HTML/CSS for the structure and styling, JavaScript for interactivity, and backend development for dynamic functionality such as forms, databases, and content management systems (CMS).",
    },
    {
        heading: "Content creation:",
        content:
            "Write and optimize the website content to align with your objectives and target audience. Ensure that the content is engaging, informative, and properly formatted for search engine optimization (SEO) purposes.",
    },
    {
        heading: "Testing and debugging:",
        content:
            "Thoroughly test the website across different browsers, devices, and screen sizes to ensure it functions properly and displays correctly. Identify and fix any bugs, broken links, or performance issues.",
    },
    {
        heading: "Launch:",
        content:
            "Once the website is fully tested and approved, it's time to deploy it to a web server and make it accessible to the public. Configure domain and hosting settings, set up SSL certificates for secure connections, and ensure proper backup mechanisms are in place.",
    },
    {
        heading: "Website maintenance and updates:",
        content:
            "Regularly monitor and maintain your website by applying security updates, fixing any issues that arise, and keeping the content fresh and up to date. This includes updating plugins, themes, and any other components used in the website.",
    },
    {
        heading: "Drive traffic and analyze performance:",
        content:
            "Implement marketing strategies such as search engine optimization (SEO), social media marketing, and content marketing to attract visitors to your website. Analyze website performance using tools like Google Analytics to track user behavior, conversions, and make data-driven improvements.",
    },
];

var features = [
    {
        heading: "Responsive Design:",
        content:
            "A responsive website adapts to different screen sizes and devices, ensuring an optimal user experience on desktops, laptops, tablets, and smartphones.",
    },
    {
        heading: "User-Friendly Interface:",
        content:
            "The website should have an intuitive and user-friendly interface, making it easy for visitors to navigate, find information, and interact with the site.",
    },
    {
        heading: "Clear Navigation:",
        content:
            "A well-structured and organized navigation menu helps users find their way around the website, accessing different pages and sections without confusion.",
    },
    {
        heading: "Compelling Visual Design:",
        content:
            "The website should have an attractive and visually appealing design that aligns with the brand identity and captures the attention of visitors. It includes the use of color schemes, typography, images, and multimedia elements.",
    },
    {
        heading: "Engaging Content:",
        content:
            "High-quality, informative, and engaging content is crucial for a website. It should be well-written, easy to read, and relevant to the target audience, providing value and encouraging visitors to stay and explore further.",
    },
    {
        heading: "Interactive Elements:",
        content:
            "Incorporating interactive elements like forms, surveys, quizzes, comment sections, social media integration, and user-generated content encourages visitor engagement, participation, and interaction with the website.",
    },
    {
        heading: "Fast Load Times:",
        content:
            "Optimizing the website's performance and ensuring fast load times is essential for user experience and search engine optimization (SEO). This can be achieved through techniques like image compression, code optimization, caching, and efficient hosting.",
    },
];

const ProcessAndFeatures = ({ data }) => {
    return (
        <div className="section-full content-inner exp-servicespb-0 margin-top-20">
            <div className="container">
                <h2 className="box-title m-t0 m-b10 text-white">
                    {data?.process?.title}
                    <span className="bg-primary" />
                </h2>
                <h4 className="text-white font-18">
                    {data?.process?.desc}
                </h4>
                <ul
                    className="list-checked primary"
                    style={{
                        fontWeight: 300,
                        color: "black",
                        lineHeight: "35px",
                    }}
                >
                    {data?.process?.data.map((item, index) => (
                        <li style={{ color: "white" }}>
                            <b className=" font-weight-600" style={{ color: "orange" }}>
                                {item.heading + " "}{" "}
                            </b>
                            {item.content}
                        </li>
                    ))}
                </ul>
                <p
                    className="text-white font-weight-300 font-16"
                    style={{ fontWeight: 300, color: "black", lineHeight: "35px", }}
                >
                    {data?.process?.enddesc}
                </p>

                <h2 className="box-title m-t0 m-b10">
                    {data?.unique?.title}
                    <span className="bg-primary" />
                </h2>

                <p
                    className="font-weight-300 font-16"
                    style={{
                        fontWeight: 300,
                        color: "white",
                        lineHeight: "35px",
                    }}
                >
                    {data?.unique?.desc}
                </p>

                <h2
                    className="box-title m-t0 m-b10 text-white"
                >
                    {data?.features?.title}
                </h2>
                <h4
                    className="text-white font-18"
                >
                    {data?.features?.desc}
                </h4>

                <ul
                    className="list-checked primary"
                    style={{
                        fontWeight: 300,
                        color: "black",
                        lineHeight: "35px",
                    }}
                >
                    {data?.features?.data.map((item, index) => (
                        <li style={{ color: "white" }}>
                            <b className="font-weight-600" style={{ color: "orange" }}>
                                {item.heading + " "}{" "}
                            </b>
                            {item.content}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default ProcessAndFeatures