import React from 'react'

var data = [
  {
    title: "Piolax | SBU transformation for Auto Manufacturing Company by S2 Integrators",
    date: "May 26, 2022",
    url: "https://s2integrators.medium.com/piolax-sbu-transformation-for-auto-manufacturing-company-by-s2-integrators-6679c3845860",
    type: "Transformation",
    image: "https://miro.medium.com/v2/resize:fit:679/1*TrQf4drig_bG5MKO1zbHMA.jpeg"
    // image: "/wp-content/uploads/2023/07/blog-1.png"
  },
  {
    date: "Jan 5, 2022",
    title: "Oracle Procurement Vs SAP Ariba",
    url: "https://s2integrators.medium.com/oracle-procurement-vs-sap-ariba-86e80f12b617",
    type: "Sap Ariba Implementation",
    image: "https://miro.medium.com/v2/resize:fit:679/1*6e6Lqb1QD8jAgLfcmMzjrQ.png"
    // image: "/wp-content/uploads/2023/07/blog-2.png"
  },
  {
    date: "Aug 31, 2021",
    title: "Analyze Snowflake data in SAP Analytics Cloud, Best of both World",
    url: "https://s2integrators.medium.com/analyze-snowflake-data-in-sap-analytics-cloud-best-of-both-world-84d4858d540a",
    type: "Snowflake",
    image: "/wp-content/uploads/2023/07/blog-9.jpg"
  },
]
const Blog = () => {



  const HeaderBG = () => {
    return (
      <div className="aboutus-container">
        <div className="">
          <div className="full-height-bg-content">
            <div className="container">
              <h2>Our Blogs</h2>
              <div className="mx-auto">
                <p>
                  We are delivering long-lasting impact through our actions as a
                  company and through our pacts with stakeholders for a more
                  sustainable future.
                </p>
                <div className="mt-3">
                  <a
                    className="learn-more-white-revamp"
                    href="/"
                  >
                    SAP Foundation
                  </a>
                  <br />
                  <a
                    className="learn-more-white-revamp"
                    href=""
                    target="_blank"
                  >
                    SAP Sustainability Annual Report 2023
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <div>
      <HeaderBG />
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-06d2df7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="06d2df7"
        data-element_type="section"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b6fd17c"
            data-id="b6fd17c"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <section
                className="elementor-section elementor-inner-section elementor-element elementor-element-06a210e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="06a210e"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8d3e86b"
                    data-id="8d3e86b"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-facee2b elementor-widget elementor-widget-softconic_heading"
                        data-id="facee2b"
                        data-element_type="widget"
                        data-widget_type="softconic_heading.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="section-title-3">
                            <h2>The Latest Blogs</h2>
                            <p>
                              VP SAP Client Acquisition S2 Integrators is founded in 2016 at Atlanta, S2 specializes in providing intelligent ERP solutions, next-gen SAP solutions and services including SAP Line of Business solutions (Ariba, SuccessFactors, Hybris, Concur and Fieldglass), S/4 HANA Migration, Finance, Business Intelligence, and Data Management.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="blogs-container elementor-element elementor-element-3f8ca92 elementor-widget elementor-widget-softconic_blog">
                <div className="elementor-widget-container">
                  {/* Style Two HTML Section Start -*/}
                  <div className="home3-blog-area">
                    <div className="row g-4">
                      {data.map((item) => (
                        <div className="col-lg-4 col-md-6">
                          <div className="single-blog widget magnetic-item">
                            <div className="blog-img">
                              <img
                                fetchpriority="high"
                                decoding="async"
                                width={1320}
                                height={560}
                                src={item.image}
                                className="attachment-post-thumbnail size-post-thumbnail wp-post-image" sizes="(max-width: 1320px) 100vw, 1320px"
                              />
                              <div className="blog-tag">
                                <a href={item.url} target='_blank'>
                                  {item.type}
                                </a>
                              </div>
                            </div>
                            <div className="blog-content">
                              <ul className="blog-meta">
                                <li>
                                  <a >{item.date}</a>
                                </li>
                                <li>
                                  <a >
                                    Comment (2)
                                  </a>
                                </li>
                              </ul>
                              <h4>
                                <a href={item.url} target='_blank'>
                                  {item.title}
                                </a>
                              </h4>
                              <div className="blog-footer">
                                <div className="read-btn">
                                  <a href={item.url} target='_blank'>
                                    Read More
                                    <svg
                                      width={12}
                                      height={12}
                                      viewBox="0 0 13 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                    </svg>
                                  </a>
                                </div>
                                <div className="social-area">
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <i className="bx bxl-facebook" />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="bx bxl-twitter" />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="bx bxl-pinterest" />
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="bx bxl-instagram" />
                                      </a>
                                    </li>
                                  </ul>
                                  <span>
                                    <svg
                                      width={15}
                                      height={15}
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.5092 14.4665C8.93952 14.4665 8.43254 14.0841 8.27608 13.5365L6.76211 8.23761L1.46319 6.72364C0.915606 6.56718 0.533203 6.0602 0.533203 5.49074C0.533203 4.91327 0.904695 4.41397 1.45764 4.24808L14.0052 0.483828C14.0761 0.462563 14.1515 0.460898 14.2233 0.47901C14.2951 0.497122 14.3607 0.534335 14.413 0.586701C14.4654 0.639066 14.5026 0.704631 14.5207 0.776439C14.5388 0.848246 14.5372 0.923617 14.5159 0.994555L10.7516 13.5421C10.5857 14.095 10.0865 14.4665 9.5092 14.4665Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Blog
