import React from 'react'

const ContactUs = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-a406e25 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="a406e25"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-default">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-51c3619"
                    data-id="51c3619"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-f19a8a1 elementor-widget elementor-widget-softconic_contact"
                            data-id="f19a8a1"
                            data-element_type="widget"
                            data-widget_type="softconic_contact.default"
                        >
                            <div className="elementor-widget-container">
                                <div className="home6-contact-section">
                                    <div className="container">
                                        <div className="row g-lg-4 gy-5 justify-content-center">
                                            <div className="col-lg-6">
                                                <div className="section-title-6 text-center">
                                                    <span>Contact</span>
                                                    <h2>Contact</h2>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="contact-form-wrap">
                                                    <div className="form-tltle">
                                                        <h5>We’re Here to Help You</h5>
                                                    </div>
                                                    <div className="contact-form">
                                                        <div
                                                            className="wpcf7 no-js"
                                                            id="wpcf7-f449-p1375-o1"
                                                            lang="en-US"
                                                            dir="ltr"
                                                        >
                                                            <div className="screen-reader-response">
                                                                <p
                                                                    role="status"
                                                                    aria-live="polite"
                                                                    aria-atomic="true"
                                                                />
                                                                <ul />
                                                            </div>
                                                            <form
                                                                // action="/"
                                                                // method="post"
                                                                className="wpcf7-form init"
                                                                aria-label="Contact form"
                                                                noValidate="novalidate"
                                                                data-status="init"
                                                            >
                                                                <div style={{ display: "none" }}>
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7"
                                                                        defaultValue={449}
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_version"
                                                                        defaultValue="5.7.7"
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_locale"
                                                                        defaultValue="en_US"
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_unit_tag"
                                                                        defaultValue="wpcf7-f449-p1375-o1"
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_container_post"
                                                                        defaultValue={1375}
                                                                    />
                                                                    <input
                                                                        type="hidden"
                                                                        name="_wpcf7_posted_data_hash"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6 mb-20">
                                                                        <div className="form-inner">
                                                                            <label>first name</label>
                                                                            <span
                                                                                className="wpcf7-form-control-wrap"
                                                                                data-name="f-name"
                                                                            >
                                                                                <input
                                                                                    size={40}
                                                                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                    aria-required="true"
                                                                                    aria-invalid="false"
                                                                                    defaultValue=""
                                                                                    type="text"
                                                                                    name="f-name"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 mb-20">
                                                                        <div className="form-inner">
                                                                            <label>Last name</label>
                                                                            <span
                                                                                className="wpcf7-form-control-wrap"
                                                                                data-name="l-name"
                                                                            >
                                                                                <input
                                                                                    size={40}
                                                                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                    aria-required="true"
                                                                                    aria-invalid="false"
                                                                                    defaultValue=""
                                                                                    type="text"
                                                                                    name="l-name"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 mb-20">
                                                                        <div className="form-inner">
                                                                            <label>Company/Organization</label>
                                                                            <span
                                                                                className="wpcf7-form-control-wrap"
                                                                                data-name="company-name"
                                                                            >
                                                                                <input
                                                                                    size={40}
                                                                                    className="wpcf7-form-control wpcf7-text"
                                                                                    aria-invalid="false"
                                                                                    defaultValue=""
                                                                                    type="text"
                                                                                    name="company-name"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 mb-20">
                                                                        <div className="form-inner">
                                                                            <label>Email</label>
                                                                            <span
                                                                                className="wpcf7-form-control-wrap"
                                                                                data-name="email-704"
                                                                            >
                                                                                <input
                                                                                    size={40}
                                                                                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                                    aria-required="true"
                                                                                    aria-invalid="false"
                                                                                    defaultValue=""
                                                                                    type="email"
                                                                                    name="email-704"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 mb-20">
                                                                        <div className="form-inner">
                                                                            <label>Phone</label>
                                                                            <span
                                                                                className="wpcf7-form-control-wrap"
                                                                                data-name="phn-number"
                                                                            >
                                                                                <input
                                                                                    size={40}
                                                                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                    aria-required="true"
                                                                                    aria-invalid="false"
                                                                                    defaultValue=""
                                                                                    type="text"
                                                                                    name="phn-number"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 mb-20">
                                                                        <div className="form-inner">
                                                                            <label>Message</label>
                                                                            <span
                                                                                className="wpcf7-form-control-wrap"
                                                                                data-name="message"
                                                                            >
                                                                                <textarea
                                                                                    cols={40}
                                                                                    rows={10}
                                                                                    className="wpcf7-form-control wpcf7-textarea"
                                                                                    aria-invalid="false"
                                                                                    name="message"
                                                                                    defaultValue={""}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="form-inner">
                                                                            <div
                                                                                className="primary-btn3"
                                                                                // type="submit"
                                                                            >
                                                                                Submit
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="wpcf7-response-output"
                                                                    aria-hidden="true"
                                                                />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs