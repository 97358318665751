import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Define your goals and objectives:",
        content:
            "Determine the purpose of your website and what you want to achieve with it. Identify your target audience, key features, and functionality requirements.",
    },
    {
        heading: "Plan and research:",
        content:
            "Conduct thorough research on your target audience, competitors, and industry trends. Create a sitemap to outline the structure and navigation of your website. Plan the content and gather any necessary resources such as images, videos, and text.",
    },
    {
        heading: "Choose a development platform:",
        content:
            "Select a suitable platform for building your website. Some popular options include WordPress, Drupal, Joomla, Shopify, or custom development using HTML, CSS, and JavaScript.",
    },
    {
        heading: "Design the user interface (UI):",
        content:
            "Create wireframes or mockups to visualize the layout and design of your website. Consider the user experience (UX) by ensuring intuitive navigation, responsive design for different devices, and accessibility for all users.",
    },
    {
        heading: "Develop the website:",
        content:
            "Start implementing the design by coding the website using the chosen platform or programming languages. This involves writing HTML/CSS for the structure and styling, JavaScript for interactivity, and backend development for dynamic functionality such as forms, databases, and content management systems (CMS).",
    },
    {
        heading: "Content creation:",
        content:
            "Write and optimize the website content to align with your objectives and target audience. Ensure that the content is engaging, informative, and properly formatted for search engine optimization (SEO) purposes.",
    },
    {
        heading: "Testing and debugging:",
        content:
            "Thoroughly test the website across different browsers, devices, and screen sizes to ensure it functions properly and displays correctly. Identify and fix any bugs, broken links, or performance issues.",
    },
    {
        heading: "Launch:",
        content:
            "Once the website is fully tested and approved, it's time to deploy it to a web server and make it accessible to the public. Configure domain and hosting settings, set up SSL certificates for secure connections, and ensure proper backup mechanisms are in place.",
    },
    {
        heading: "Website maintenance and updates:",
        content:
            "Regularly monitor and maintain your website by applying security updates, fixing any issues that arise, and keeping the content fresh and up to date. This includes updating plugins, themes, and any other components used in the website.",
    },
    {
        heading: "Drive traffic and analyze performance:",
        content:
            "Implement marketing strategies such as search engine optimization (SEO), social media marketing, and content marketing to attract visitors to your website. Analyze website performance using tools like Google Analytics to track user behavior, conversions, and make data-driven improvements.",
    },
];

var features = [
    {
        heading: "Responsive Design:",
        content:
            "A responsive website adapts to different screen sizes and devices, ensuring an optimal user experience on desktops, laptops, tablets, and smartphones.",
    },
    {
        heading: "User-Friendly Interface:",
        content:
            "The website should have an intuitive and user-friendly interface, making it easy for visitors to navigate, find information, and interact with the site.",
    },
    {
        heading: "Clear Navigation:",
        content:
            "A well-structured and organized navigation menu helps users find their way around the website, accessing different pages and sections without confusion.",
    },
    {
        heading: "Compelling Visual Design:",
        content:
            "The website should have an attractive and visually appealing design that aligns with the brand identity and captures the attention of visitors. It includes the use of color schemes, typography, images, and multimedia elements.",
    },
    {
        heading: "Engaging Content:",
        content:
            "High-quality, informative, and engaging content is crucial for a website. It should be well-written, easy to read, and relevant to the target audience, providing value and encouraging visitors to stay and explore further.",
    },
    {
        heading: "Interactive Elements:",
        content:
            "Incorporating interactive elements like forms, surveys, quizzes, comment sections, social media integration, and user-generated content encourages visitor engagement, participation, and interaction with the website.",
    },
    {
        heading: "Fast Load Times:",
        content:
            "Optimizing the website's performance and ensuring fast load times is essential for user experience and search engine optimization (SEO). This can be achieved through techniques like image compression, code optimization, caching, and efficient hosting.",
    },
];
const WebDevelopment = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Website Development",
                    desc: "Our of experienced developers are well-versed in popular back-end technologies and frameworks such as Node.js, Express.js, Laravel, ASP.NET, and Django."
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Website Development",
                        desc: "Our website development solutions come with a range of features designed to help businesses create a strong online presence. We create a website that reflects your brand identity and meets your business objectives. We ensure that your website is responsive across all devices, providing a seamless user experience. We develop e-commerce websites that are optimized for conversions and provide a seamless user experience for customers. We develop websites with search engine optimization in mind to ensure that your website ranks high in search engine results pages.",
                        image: "/assets/images/services/web-dev-01.jpeg",
                        menus: [
                            "Custom Software",
                            "Enterprise Software",
                            "Web Application",
                            "Software Consulting",
                            "Mobile Application",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "We Are Working With Creative User",
                        desc: "We are a team of experienced web developers who specialize in creating custom websites for businesses of all sizes. Here is some information about our website development services and how we can help your business create a strong online presence. We create customized websites that are tailored to meet the specific needs of your business. We develop websites with content management systems, such as WordPress, that allow you to easily update and manage your website content. We develop e-commerce websites that are optimized for conversions and provide a seamless user experience for customers. We develop websites that are optimized for all devices, including desktops, tablets, and mobile devices. We provide ongoing maintenance and support services to ensure that your website remains up-to-date and functional.",
                        image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }

                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Process Of Creating A Website",
                        desc: "Here is an overview of the typical process followed by software companies in creating a website",
                        data: process,
                        enddesc: "It's important to note that the website development process can vary depending on the specific project, client needs, and software company practices. However, the steps mentioned above provide a general overview of the typical process followed by software companies in creating a website."
                    },
                    unique: {
                        title: "Unique Design",
                        desc: "Create visually stunning and interactive user interfaces that engage and captivate users. Implement innovative design elements such as parallax scrolling, animated transitions, or interactive hover effects to create a memorable and immersive user experience. Develop custom illustrations and graphics that bring a distinctive and personalized touch to your designs. These can include hand-drawn icons, illustrations, or unique visual elements that align with the brand identity and create a memorable impression."
                    },
                    features: {
                        title: "Features",
                        desc: "Here are some common features of web development for software companies",
                        data: features
                    }
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default WebDevelopment