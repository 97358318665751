import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Customized Solutions:",
        content:
            "We understands clearly that every business is unique in its own way. That’s why we try to understand the function of your business before creating an AI tool for it. We create AI solutions based on your needs, such as making your regular tasks automatic, understanding your customer behavior, and many more.",
    },
    {
        heading: "Real Results:",
        content:
            "Using AI for your business can be game-changing, and it provides real-time results without any delay. Whether it be chatbots or predictions, AI can help you out in fractions of seconds. We has many years of proven experience in turning your AI concepts into reality. We believe that our team of experts in AI can help you in your transformation journey.",
    },
    {
        heading: "Easy Integration:",
        content:
            "As a leading AI and ML company, we believe that complete AI integration is necessary, and we do not only provide the AI product; we also make sure that it works and fits in your existing systems. S2Integrators gives complete control of the AI system to its clients, and we provide details and necessary training with regular support.",
    },
];

var features = [
    {
        heading: "Automation:",
        content:
            "Say goodbye to the regular tasks and start using AI to automate your business so that your team can focus more on any important work, making your business more successful.",
    },
    {
        heading: "Smart Insights:",
        content:
            "AI understands your customer behavior and makes better decisions using the data from AI insights.",
    },
    {
        heading: "Cost Saving:",
        content:
            "AI can save your costs in the long run and improve efficiency.",
    },
];
const AiMl = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "AI & ML",
                    desc: "We use Artificial Intelligence algorithms to help machines learn themselves from the data they are exposed to and arrive at conclusions, simulating human intelligence"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "AI & ML",
                        desc: "Artificial Intelligence (AI) is rapidly transforming how businesses operate and can potentially revolutionize the world as we know it. At S2Integrators, we understand the potential of this cutting-edge technology and offer a range of services to help businesses leverage it to their advantage. S2Integrators provides a range of AI-powered services that help businesses and organizations to automate tasks, improve efficiency, and gain insights into complex data. Our AI experts work closely with our clients to understand their needs and develop customized solutions that meet their requirements.",
                        image: "https://img.freepik.com/premium-vector/ai-technology_46706-532.jpg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Understanding Requirements",
                            "Design and Development",
                            "Integration",
                            "Testing and launch",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "AI & ML",
                        desc: "As a leading IT company, S2Integrators offers many services to help our clients stay ahead in the ever-evolving technology landscape. One of our core competencies is Machine Learning, a powerful tool that has revolutionized how organizations approach data analysis. Our Machine Learning services are designed to help businesses harness the power of big data, transform it into valuable insights, and automate decision-making processes. Our team of experts has extensive experience in developing cutting-edge algorithms and models that can help businesses unlock the full potential of their data.",
                        image: "https://img.freepik.com/premium-photo/futuristic-robot-artificial-intelligence-concept_31965-4087.jpg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Why Choose Us",
                        desc: "",
                        data: process,
                        enddesc: ""
                    },
                    features: {
                        title: "What AI can do for your business?",
                        desc: "",
                        data: features,
                        enddesc: ""
                    },
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default AiMl