import React from 'react'
import CountUp from 'react-countup';

const WhyChooseUs = () => {
    return (
        <>


            <div className="elementor-widget-wrap elementor-element-populated" >
                <div
                    className="elementor-element elementor-element-ae41a5c elementor-widget elementor-widget-softconic_clients"
                    data-id="ae41a5c"
                    data-element_type="widget"
                    data-widget_type="softconic_clients.default"
                >
                    <div className="elementor-widget-container">
                        <div className="home5-partner-area" style={{ background: "black", paddingBottom: 0, paddingTop: 0 }}>
                            <div className="container">
                                <div className="row mb-3">
                                    <div className="col-lg-12 d-flex align-items-center justify-content-between flex-wrap gap-2">
                                        <div className="section-title-5">
                                            <span>Why Choose Us</span>
                                            <h2>We Provide Flexible</h2>
                                        </div>
                                        <div className="section-content">
                                            <p>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section
                className="elementor-section elementor-inner-section elementor-element elementor-element-2ca8db6 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="2ca8db6"
                data-element_type="section"
            >

                <div className="elementor-container elementor-column-gap-default">
                    <div
                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-951f9b4"
                        data-id="951f9b4"
                        data-element_type="column">
                    </div>
                </div>
            </section>



            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-943b93b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="943b93b"
                data-element_type="section"
            >
                <div className="elementor-container elementor-column-gap-default">
                    <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-836e7ab"
                        data-id="836e7ab"
                        data-element_type="column"
                    >
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                                className="elementor-element elementor-element-938c1b8 elementor-widget elementor-widget-softconic_about_four"
                                data-id="938c1b8"
                                data-element_type="widget"
                                data-widget_type="softconic_about_four.default"
                            >
                                <div className="elementor-widget-container">
                                    {/* Start Why Choose section */}
                                    <div className="home6-choose-section">
                                        <div className="container">
                                            <div className="row">
                                                <div className="choose-top">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="choose-title">
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 choose-us-right-img">
                                                            <div className="choose-right-img magnetic-item">
                                                                <img
                                                                    decoding="async"
                                                                    className="img-fluid"
                                                                    src="/wp-content/uploads/2023/07/choose-img-1.png"
                                                                    alt="right-corner-image"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="choose-btm">
                                                    <div className="row  g-lg-4 gy-5">
                                                        <div className="col-lg-7">
                                                            <div className="choose-left-content">
                                                                <div className="vectors">
                                                                    <div className="choose-vec-top-r">
                                                                        <svg
                                                                            width={95}
                                                                            height={95}
                                                                            viewBox="0 0 95 95"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <rect
                                                                                opacity="0.05"
                                                                                width={95}
                                                                                height={95}
                                                                                fill="#06D889"
                                                                            />
                                                                            <rect
                                                                                opacity="0.05"
                                                                                y={5}
                                                                                width={90}
                                                                                height={90}
                                                                                fill="#06D889"
                                                                            />
                                                                            <rect
                                                                                opacity="0.05"
                                                                                y={10}
                                                                                width={85}
                                                                                height={85}
                                                                                fill="#06D889"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="choose-vec-btm-l">
                                                                        <svg
                                                                            width={80}
                                                                            height={80}
                                                                            viewBox="0 0 80 80"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <rect
                                                                                opacity="0.1"
                                                                                width={80}
                                                                                height={80}
                                                                                fill="#06D889"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div className="icon">
                                                                    <img
                                                                        decoding="async"
                                                                        className="img-fluid"
                                                                        src="/wp-content/uploads/logo/s2logo.png"
                                                                        alt="left-logo-image"
                                                                        style={{ width: "100px" }}
                                                                    />
                                                                </div>
                                                                <h4>
                                                                    Founded in 2016 at Atlanta, S2 specializes in providing intelligent <span>ERP solutions.</span>
                                                                </h4>
                                                                <p>
                                                                    We have been empowering our clients with top-notch ERP solutions, transformation assets and talents since 2016. We have partnered with many Global Fortune 500 companies and large corporations where we have remodelled processes and driven efficiency levels by leveraging our SAP solutions and skills.
                                                                </p>
                                                                <div className="sl">
                                                                    <h2>#1</h2>
                                                                </div>
                                                                <div className="about-btn">
                                                                    <a
                                                                        href=""
                                                                        target="_blank"
                                                                        rel="nofollow"
                                                                    >
                                                                        <svg
                                                                            width={7}
                                                                            height={7}
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                                                        </svg>
                                                                        KNOW US{" "}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5 choose-feature-container">
                                                            <div className="choose-feature">
                                                                <ul>
                                                                    <li>
                                                                        <div className="single-feature">
                                                                            <div className="progress">
                                                                                <h3>
                                                                                    {/* <span className="counter">100 </span> */}
                                                                                    <CountUp className="counter-number" end={100} enableScrollSpy />

                                                                                    %
                                                                                </h3>
                                                                            </div>
                                                                            <div className="content">
                                                                                <h4>On Time Delivery</h4>
                                                                                <p>
                                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="single-feature">
                                                                            <div className="progress">
                                                                                <h3>
                                                                                    {/* <span className="counter">99 </span> */}
                                                                                    <CountUp className="counter-number" end={99} enableScrollSpy />
                                                                                    %
                                                                                </h3>
                                                                            </div>
                                                                            <div className="content">
                                                                                <h4>Happy Clients</h4>
                                                                                <p>
                                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Why Choose section */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs