import React from 'react'
import WhyChooseUs from "../home/WhyChooseUs.js"
import Review from "../home/Review.js"
import ContactUs from "../home/ContactUs.js"


var data = [
  {
    title: "Piolax | SBU transformation for Auto Manufacturing Company by S2 Integrators",
    date: "May 26, 2022",
    url: "https://s2integrators.medium.com/piolax-sbu-transformation-for-auto-manufacturing-company-by-s2-integrators-6679c3845860",
    type: "Transformation",
    image: "https://miro.medium.com/v2/resize:fit:679/1*TrQf4drig_bG5MKO1zbHMA.jpeg"
    // image: "/wp-content/uploads/2023/07/blog-1.png"
  },
  {
    date: "Jan 5, 2022",
    title: "Oracle Procurement Vs SAP Ariba",
    url: "https://s2integrators.medium.com/oracle-procurement-vs-sap-ariba-86e80f12b617",
    type: "Sap Ariba Implementation",
    image: "https://miro.medium.com/v2/resize:fit:679/1*6e6Lqb1QD8jAgLfcmMzjrQ.png"
    // image: "/wp-content/uploads/2023/07/blog-2.png"
  },
  {
    date: "Aug 31, 2021",
    title: "Analyze Snowflake data in SAP Analytics Cloud, Best of both World",
    url: "https://s2integrators.medium.com/analyze-snowflake-data-in-sap-analytics-cloud-best-of-both-world-84d4858d540a",
    type: "Snowflake",
    image: "/wp-content/uploads/2023/07/blog-9.jpg"
  },
]

const About = () => {
  return (
    <div>
      <div>
        <section
          className="breadcrumbs"
          style={{
            backgroundImage:
              "url(wp-content/uploads/elementor/img/AboutBanner.png)"
          }}
        >
          <div className="breadcrumb-sm-images">
            <div className="magnetic-wrap">
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-wrapper">
                  <div className="breadcrumb-cnt">
                    <h1>About </h1>
                    <div className="breadcrumb-list">
                      <a href="">Home</a>
                      <svg
                        width={16}
                        height={9}
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.0919919 4.87815C0.144379 4.95392 0.237065 5.05915 0.297512 5.10545C0.410347 5.18964 0.454675 5.19385 1.81675 5.19385C3.17883 5.19385 3.22315 5.18964 3.33599 5.10545C3.5536 4.93708 3.63822 4.75608 3.62211 4.49511C3.60196 4.22151 3.49315 4.0363 3.29166 3.93528C3.1748 3.87635 2.96928 3.86793 1.80869 3.86793C0.494973 3.86793 0.462734 3.87214 0.309601 3.96474C0.0113956 4.14153 -0.0933793 4.59192 0.0919919 4.87815Z"
                          fill="white"
                        />
                        <path
                          d="M4.74664 4.75221C4.80306 4.89533 4.93201 5.05107 5.0529 5.12263C5.1738 5.18998 5.49215 5.19419 9.37286 5.21523L13.5639 5.23628L12.0446 6.57904C11.0573 7.45036 10.5012 7.97231 10.4609 8.0607C10.3239 8.36377 10.4488 8.77207 10.7228 8.9236C10.88 9.01621 11.1822 9.02462 11.3152 8.94886C11.3636 8.91939 12.4073 8.01019 13.6404 6.9242C15.1476 5.59407 15.8971 4.90375 15.9415 4.81535C16.0623 4.54175 15.9938 4.24289 15.7561 4.00717C15.3571 3.61992 11.287 0.105177 11.1782 0.0546658C11.0049 -0.0253103 10.8558 -0.0168913 10.6584 0.0757124C10.34 0.227247 10.203 0.681847 10.3763 0.989124C10.4166 1.06068 11.146 1.72575 11.9963 2.46237C12.8425 3.2032 13.5397 3.81775 13.5397 3.83459C13.5437 3.85564 11.6618 3.86827 9.35674 3.86827L5.1738 3.86827L5.04082 3.96087C4.83932 4.10819 4.75067 4.25973 4.73455 4.48703C4.72649 4.59647 4.73052 4.71854 4.74664 4.75221Z"
                          fill="white"
                        />
                      </svg>
                      About
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="page-wrapper sec-mar">
          <div className="page-content-wrapper">
            <div
              data-elementor-type="wp-page"
              data-elementor-id={15}
              className="elementor elementor-15"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-e773199 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="e773199"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-708387c"
                    data-id="708387c"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-5b9417c elementor-widget elementor-widget-softconic_about"
                        data-id="5b9417c"
                        data-element_type="widget"
                        data-widget_type="softconic_about.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="home3-about-section">
                            <div className="container-fluid">
                              <div className="section-title-5">

                              </div>
                              <div className="row g-lg-4 gy-5">
                                <div className="col-lg-12">
                                  <div className="about-left">
                                    <div className="about-img">
                                      <img
                                        decoding="async"
                                        className="img-fluid magnetic-item"
                                        src="/wp-content/uploads/2023/07/home3-about-1.jpg"
                                        alt="about-left-image"
                                      />
                                    </div>
                                    <div className="about-content" style={{ maxWidth: "fit-content" }}>
                                      <h2>About us</h2>
                                      <span>Accelerated Sustainable Transformation</span>
                                      <h4>We help businesses transform to an innovative world</h4>
                                      <p>
                                        Founded in 2016 at Atlanta, S2 specializes in providing intelligent ERP solutions, next-gen SAP solutions and services including SAP Line of Business solutions (Ariba, SuccessFactors, Hybris, Concur and Fieldglass), S/4 HANA Migration, Finance, Business Intelligence, and Data Management.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <WhyChooseUs />
              <Review />

              <img src="/wp-content/uploads/elementor/img/viewmap.png" />
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-2476217 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id={2476217}
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-no">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dbbf137"
                    data-id="dbbf137"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-7919f00 elementor-widget elementor-widget-softconic_testimonial_two"
                        data-id="7919f00"
                        data-element_type="widget"
                        data-widget_type="softconic_testimonial_two.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="home3-testimonil-area">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12 d-flex align-items-center justify-content-between gap-4 flex-wrap">
                                  <div className="section-title-3">
                                    <h2>Happy Customers</h2>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                      dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
                                    </p>
                                  </div>
                                  <div className="swiper-btn-group">
                                    <div className="swiper-btn prevbtn2">
                                      <i className="bi bi-arrow-left" />
                                    </div>
                                    <div className="swiper-btn nextbtn2">
                                      <i className="bi bi-arrow-right" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="swiper home3-testimonial-slider">
                                    <div className="swiper-wrapper">
                                      <div className="swiper-slide">
                                        <div className="testimonial-card3">
                                          <div className="quate-icon">
                                            <img
                                              decoding="async"
                                              src="/wp-content/themes/softconic/assets/img/home-4/left-quote.svg"
                                              alt="_left-quote-image"
                                            />
                                          </div>
                                          <div className="testimonial-top">
                                            <div className="review-left">
                                              <img
                                                decoding="async"
                                                src="https://qcodesinfotech.com/images/newlogo4.webp"
                                                alt="company-logo"
                                              />
                                            </div>
                                            <div className="review-right">
                                              <ul className="star">
                                                <li>
                                                  <i className="bi bi-star-fill" />
                                                </li>
                                                <li>
                                                  <i className="bi bi-star-fill" />
                                                </li>
                                                <li>
                                                  <i className="bi bi-star-fill" />
                                                </li>
                                                <li>
                                                  <i className="bi bi-star-fill" />
                                                </li>
                                                <li>
                                                  <i className="bi bi-star-empty" />
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="testimonial-content">
                                            <p>
                                              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat”
                                            </p>
                                          </div>
                                          <div className="testimonial-btm">
                                            <div className="author-area">
                                              <div className="author-content">
                                                <h4>Muazzim Hussain</h4>
                                                <span>CEO At qcodesinfotech.com</span>
                                              </div>
                                            </div>
                                            <div className="review-date-and-time"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-06d2df7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="06d2df7"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b6fd17c"
                    data-id="b6fd17c"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-06a210e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="06a210e"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8d3e86b"
                            data-id="8d3e86b"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-facee2b elementor-widget elementor-widget-softconic_heading"
                                data-id="facee2b"
                                data-element_type="widget"
                                data-widget_type="softconic_heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="section-title-3">
                                    <h2>The Latest Blogs</h2>
                                    <p>
                                      VP SAP Client Acquisition S2 Integrators is founded in 2016 at Atlanta, S2 specializes in providing intelligent ERP solutions, next-gen SAP solutions and services including SAP Line of Business solutions (Ariba, SuccessFactors, Hybris, Concur and Fieldglass), S/4 HANA Migration, Finance, Business Intelligence, and Data Management.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <div className="blogs-container elementor-element elementor-element-3f8ca92 elementor-widget elementor-widget-softconic_blog" >
                        <div className="elementor-widget-container">
                          {/* Style Two HTML Section Start -*/}
                          <div className="home3-blog-area">
                            <div className="row g-4">
                              {data.map((item) => (
                                <div className="col-lg-4 col-md-6">
                                  <div className="single-blog widget magnetic-item">
                                    <div className="blog-img">
                                      <img
                                        fetchpriority="high"
                                        decoding="async"
                                        width={1320}
                                        height={560}
                                        src={item.image}
                                        className="attachment-post-thumbnail size-post-thumbnail wp-post-image" sizes="(max-width: 1320px) 100vw, 1320px"
                                      />
                                      <div className="blog-tag">
                                        <a href={item.url} target='_blank'>
                                          {item.type}
                                        </a>
                                      </div>
                                    </div>
                                    <div className="blog-content">
                                      <ul className="blog-meta">
                                        <li>
                                          <a >{item.date}</a>
                                        </li>
                                        <li>
                                          <a >
                                            Comment (2)
                                          </a>
                                        </li>
                                      </ul>
                                      <h4>
                                        <a href={item.url} target='_blank'>
                                          {item.title}
                                        </a>
                                      </h4>
                                      <div className="blog-footer">
                                        <div className="read-btn">
                                          <a href={item.url} target='_blank'>
                                            Read More
                                            <svg
                                              width={12}
                                              height={12}
                                              viewBox="0 0 13 13"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                            </svg>
                                          </a>
                                        </div>
                                        <div className="social-area">
                                          <ul>
                                            <li>
                                              <a href="#">
                                                <i className="bx bxl-facebook" />
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <i className="bx bxl-twitter" />
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <i className="bx bxl-pinterest" />
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <i className="bx bxl-instagram" />
                                              </a>
                                            </li>
                                          </ul>
                                          <span>
                                            <svg
                                              width={15}
                                              height={15}
                                              viewBox="0 0 15 15"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M9.5092 14.4665C8.93952 14.4665 8.43254 14.0841 8.27608 13.5365L6.76211 8.23761L1.46319 6.72364C0.915606 6.56718 0.533203 6.0602 0.533203 5.49074C0.533203 4.91327 0.904695 4.41397 1.45764 4.24808L14.0052 0.483828C14.0761 0.462563 14.1515 0.460898 14.2233 0.47901C14.2951 0.497122 14.3607 0.534335 14.413 0.586701C14.4654 0.639066 14.5026 0.704631 14.5207 0.776439C14.5388 0.848246 14.5372 0.923617 14.5159 0.994555L10.7516 13.5421C10.5857 14.095 10.0865 14.4665 9.5092 14.4665Z"
                                                fill="white"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <ContactUs />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default About
