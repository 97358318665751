import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Understanding your requirements:",
        content:
            "We start by gathering information about your institution, its goals, and its audience. We use this information to create a detailed project brief that outlines the scope of the project.",
    },
    {
        heading: "Design and development:",
        content:
            "Once the project brief is approved, we begin designing and developing the mobile application. We create wireframes and mockups to give you an idea of what the application will look like, and we work with you to refine the design until it meets your requirements.",
    },
    {
        heading: "Integration:",
        content:
            "We integrate third-party systems, such as online ordering and reservation systems, into the website to ensure that it meets your requirements.",
    },
    {
        heading: "Testing and launch:",
        content:
            "We test the website thoroughly to ensure that it is functioning as expected and is user-friendly. Once the website is tested and approved, we launch it and provide ongoing support and maintenance.",
    },
];

var features = [
    {
        heading: "",
        content:
            "We have a team of experienced and skilled developers and designers who are dedicated to creating exceptional educational websites.",
    },
    {
        heading: "",
        content:
            "We follow a comprehensive development process that ensures that we deliver a website that meets your requirements and provides an exceptional user experience.",
    },
    {
        heading: "",
        content:
            "We use the latest mobile application development technologies and best practices to ensure that your website is secure, scalable, and easy to use.",
    },
];
const MobileDevelopment = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Mobile App Development",
                    desc: "We specialize in creating innovative and high-quality mobile applications that cater to the unique needs of our clients. Our expertise lies in delivering custom mobile app solutions for various platforms, including iOS and Android."
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Mobile Development",
                        desc: "A leading mobile app development software company that combines technical expertise with creative excellence to bring your app ideas to life. With a team of experienced developers, designers, and project managers, we are dedicated to delivering cutting-edge mobile solutions that drive business growth and enhance user experiences. At S2Integrators, we understand that every business is unique, and so are their app requirements. We specialize in custom mobile app development, tailoring our solutions to match your specific objectives and target audience. Whether you need a consumer-facing app, enterprise app, or mobile platform for your business, we have the expertise to create a seamless and engaging mobile experience.",
                        image: "/assets/images/services/mobile-dev-01.jpeg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Understanding Requirements",
                            "Design and Development",
                            "Integration",
                            "Testing and launch",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "We Are Working With Creative User",
                        desc: "We are a team of experienced web developers who specialize in creating custom websites for businesses of all sizes. Here is some information about our website development services and how we can help your business create a strong online presence. We create customized websites that are tailored to meet the specific needs of your business. We develop websites with content management systems, such as WordPress, that allow you to easily update and manage your website content. We develop e-commerce websites that are optimized for conversions and provide a seamless user experience for customers. We develop websites that are optimized for all devices, including desktops, tablets, and mobile devices. We provide ongoing maintenance and support services to ensure that your website remains up-to-date and functional.",
                        image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Our Development Process",
                        desc: "We follow a comprehensive development process to ensure that we create mobile applications that meet your requirements and provide an exceptional user experience.",
                        data: process,
                        enddesc: "If you are interested in creating a custom mobile application for your business, please contact us today to discuss your requirements and how we can help you achieve your goals"
                    },
                    unique: {
                        title: "Unique Design",
                        desc: "Create visually stunning and interactive user interfaces that engage and captivate users. Implement innovative design elements such as parallax scrolling, animated transitions, or interactive hover effects to create a memorable and immersive user experience. Develop custom illustrations and graphics that bring a distinctive and personalized touch to your designs. These can include hand-drawn icons, illustrations, or unique visual elements that align with the brand identity and create a memorable impression."
                    },
                    features: {
                        title: "Why Choose Us",
                        desc: "Here are some common features of mobile development for software companies",
                        data: features
                    }
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default MobileDevelopment