import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "IoT Strategy & Consulting:",
        content:
            "We help our clients create an IoT strategy aligning with their business goals and objectives. Our experts analyze the client's industry, market trends, and technology to determine the best approach for their IoT implementation.",
    },
    {
        heading: "IoT Architecture & Design:",
        content:
            "Our team designs a robust and scalable IoT architecture that can handle the massive amounts of data IoT devices generate. We use the latest technology and methodologies to ensure our clients get the best solutions. solutions.",
    },
    {
        heading: "IoT Device & Sensor Management:",
        content:
            "We offer solutions to help clients manage and maintain their IoT devices and sensors. Our experts have experience working with a wide range of IoT devices and sensors and can help our clients keep their systems up and running at all times.",
    },
    {
        heading: "IoT Security & Privacy:",
        content:
            "Security and privacy have become critical issues with the growing number of IoT devices and the increasing amount of data they generate. We provide solutions to help clients secure their IoT systems and protect their data from cyber-attacks and breaches.",
    },
];

var features = [
    {
        heading: "IoT in healthcare:",
        content:
            "IoT in healthcare can be a blessing. Wearable devices can monitor real-time data about an individual's health. IoT in healthcare ensures that patient outcomes are maximized while reducing healthcare costs.",
    },
    {
        heading: "IoT in Manufacturing sector:",
        content:
            "Businesses can easily monitor the performance of the machines and predict maintenance needs. Thus reducing the cost of any downtime in the business.",
    },
    {
        heading: "IoT in Agriculture:",
        content:
            "The management of resources in the agriculture field is difficult without the use of IoT. Using IoT in agriculture, we can improve crop yields and manage resources effectively. IoT can help farmers make the best decisions based on data and predictions.",
    },
    {
        heading: "IoT in Smart Homes:",
        content:
            "IoT in smart homes has gained popularity in recent times because IoT can help homes become more secure. IoT allows homeowners to have remote control of their homes, thus increasing their home security.",
    },
];
const InternetOfThings = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Internet of Things",
                    desc: "Our team of experts leverages the power of IoT devices and AI algorithms to provide real-time data analysis and intelligent decision-making capabilities"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Internet of Things",
                        desc: "We offers a comprehensive suite of IoT services designed to help businesses harness the power of the Internet of Things. Our services include IoT strategy and planning, IoT device design and development, IoT platform deployment and management, and IoT data analysis and insights. IoT (Internet of Things) has become a buzzword in today's technology-driven world. We are at the forefront of the IoT revolution and provide cutting-edge solutions to our clients. Our team of experts has extensive experience in developing and implementing IoT solutions that meet each of our client's unique needs",
                        image: "https://img.freepik.com/free-photo/isometric-view-3d-rendering-neon-city_23-2150900801.jpg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Understanding Requirements",
                            "Design and Development",
                            "Integration",
                            "Testing and launch",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "Internet of Things",
                        desc: "We offers a comprehensive suite of IoT services designed to help businesses harness the power of the Internet of Things. Our services include IoT strategy and planning, IoT device design and development, IoT platform deployment and management, and IoT data analysis and insights. IoT (Internet of Things) has become a buzzword in today's technology-driven world. We are at the forefront of the IoT revolution and provide cutting-edge solutions to our clients. Our team of experts has extensive experience in developing and implementing IoT solutions that meet each of our client's unique needs",
                        image: "https://img.freepik.com/premium-photo/smart-home-interface-with-augmented-realty-iot-object-interior-design_756748-3174.jpg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Why Choose Us",
                        desc: "",
                        data: process,
                        enddesc: "At S2Integrators, we think of bringing a device that is advanced, and every object is connected to each other. Our experts make use of the IoT (Internet of Things) to achieve this idea so that communication becomes easier and your life becomes simple and fast."
                    },
                    features: {
                        title: "Uses of IoT",
                        desc: "S2Integrators is always up to date with the latest technology in blockchain, and we ensure that we use the latest technology in your project for highly secure data for the modern world.",
                        data: features,
                        enddesc: ""
                    },
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default InternetOfThings