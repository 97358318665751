import React from 'react'
var data = [
    {
        no: "01",
        title: "ERP Consulting",
        desc: "Our experts will guide your transformation from legacy software and help reinvent your business process end-to-end. Fast, simple and powerful…, Niche ERP Products, Analytics, Big data, Data management, Digital transformation",
    },
    {
        no: "02",
        title: "SAP Solution",
        desc: "We can help connect your systems, drive improvement in business process and transform your challenges into great results. 360-degree view of the Customer. Ariba Integration Analytics",
    },
    {
        no: "03",
        title: "SAP Ariba",
        desc: "We collaborate with you and help perform business transformations, formulate critical solutions and strategic initiatives.",
    },
]
const WhatWeDo = () => {
    return (
        <div>

            <div className="elementor-widget-wrap elementor-element-populated" >
                <div
                    className="elementor-element elementor-element-ae41a5c elementor-widget elementor-widget-softconic_clients"
                    data-id="ae41a5c"
                    data-element_type="widget"
                    data-widget_type="softconic_clients.default"
                >
                    <div className="elementor-widget-container">
                        <div className="home5-partner-area" style={{ background: "black", paddingBottom: 0, paddingTop: 0 }}>
                            <div className="container">
                                <div className="row" style={{marginTop:"50px"}}>
                                    <div className="col-lg-12 d-flex align-items-center justify-content-between flex-wrap gap-2">
                                        <div className="section-title-5">
                                            <span>THE PROCESS</span>
                                            <h2>What We Do</h2>
                                        </div>
                                        <div className="section-content">
                                            <p>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-cc9bd01 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="cc9bd01"
                data-element_type="section"
            >
                <div className="elementor-container elementor-column-gap-default">
                    <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-062c276"
                        data-id="062c276"
                        data-element_type="column"
                    >
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-2ca8db6 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="2ca8db6"
                                data-element_type="section"
                            >
                            </section>
                            <div
                                className="elementor-element elementor-element-5db734c elementor-widget elementor-widget-softconic_process"
                                data-id="5db734c"
                                data-element_type="widget"
                                data-widget_type="softconic_process.default"
                            >
                                <div className="elementor-widget-container">

                                    <div className="section-title-4 text-center two">
                                        <h2 style={{ color: "gray" }}>
                                            We help you achieve business goals and objectives through <span>SAP Solutions</span>, Consulting, and Services.
                                        </h2>
                                    </div>
                                    <div className="home5-process-area">
                                        <div className="row g-lg-4 gy-5 justify-content-center">
                                            {
                                                data.map((item, index) => (
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="single-process">
                                                            <div className="sl">
                                                                <h2>{item.no}</h2>
                                                            </div>
                                                            <div className="content">
                                                                <h3>{item.title}</h3>
                                                                <p>{item.desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-8b83bbb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="8b83bbb"
                data-element_type="section"
            >
                <div className="elementor-container elementor-column-gap-default">
                    <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6eacecb"
                        data-id="6eacecb"
                        data-element_type="column"
                    >
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                                className="elementor-element elementor-element-62d5340 elementor-widget elementor-widget-softconic_achivement"
                                data-id="62d5340"
                                data-element_type="widget"
                                data-widget_type="softconic_achivement.default"
                            >
                                <div className="elementor-widget-container">
                                    <div className="home5-process-area">
                                        <div className="achievement-area">
                                            <div className="row g-lg-4 gy-5">
                                                <div className="col-xl-7 col-lg-6 d-flex align-items-center">
                                                    <div className="achievement-content">
                                                        <h2>#1</h2>
                                                        <h3>
                                                            Best SAP Partner In Your Region{" "}
                                                            <span>
                                                                Since <span className="year">2020.</span>
                                                            </span>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-xl-5 col-lg-6">
                                                    <div className="magnetic-wrap">
                                                        <div
                                                            className="achievement-img magnetic-item"
                                                            style={{ transform: "translate(0.7129px, -5.0152px)" }}
                                                        >
                                                            <img
                                                                decoding="async"
                                                                src="/wp-content/uploads/elementor/img/home-achive.png"
                                                                alt="achievement-image"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WhatWeDo