import React from 'react'

const Project = () => {
    return (
        <div>
            <section
  className="breadcrumbs"
  style={{
    backgroundImage:
      "url(/wp-content/plugins/softconic-core/inc/theme-options/images/breadcrumb/breadcrumb-bg.png)"
  }}
>
  <div className="breadcrumb-sm-images">
    <div className="magnetic-wrap">
      <div className="magnetic-wrap">
        <div className="inner-banner-2 magnetic-item">
          <img
            src="/wp-content/plugins/softconic-core/inc/theme-options/images/breadcrumb/breadcrumb-right-img.png"
            alt="breadcrumb-sm-image"
          />
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="breadcrumb-wrapper">
          <div className="breadcrumb-cnt">
            <h1>Project </h1>
            <div className="breadcrumb-list">
              <a href="">Home</a>
              <svg
                width={16}
                height={9}
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.0919919 4.87815C0.144379 4.95392 0.237065 5.05915 0.297512 5.10545C0.410347 5.18964 0.454675 5.19385 1.81675 5.19385C3.17883 5.19385 3.22315 5.18964 3.33599 5.10545C3.5536 4.93708 3.63822 4.75608 3.62211 4.49511C3.60196 4.22151 3.49315 4.0363 3.29166 3.93528C3.1748 3.87635 2.96928 3.86793 1.80869 3.86793C0.494973 3.86793 0.462734 3.87214 0.309601 3.96474C0.0113956 4.14153 -0.0933793 4.59192 0.0919919 4.87815Z"
                  fill="white"
                />
                <path
                  d="M4.74664 4.75221C4.80306 4.89533 4.93201 5.05107 5.0529 5.12263C5.1738 5.18998 5.49215 5.19419 9.37286 5.21523L13.5639 5.23628L12.0446 6.57904C11.0573 7.45036 10.5012 7.97231 10.4609 8.0607C10.3239 8.36377 10.4488 8.77207 10.7228 8.9236C10.88 9.01621 11.1822 9.02462 11.3152 8.94886C11.3636 8.91939 12.4073 8.01019 13.6404 6.9242C15.1476 5.59407 15.8971 4.90375 15.9415 4.81535C16.0623 4.54175 15.9938 4.24289 15.7561 4.00717C15.3571 3.61992 11.287 0.105177 11.1782 0.0546658C11.0049 -0.0253103 10.8558 -0.0168913 10.6584 0.0757124C10.34 0.227247 10.203 0.681847 10.3763 0.989124C10.4166 1.06068 11.146 1.72575 11.9963 2.46237C12.8425 3.2032 13.5397 3.81775 13.5397 3.83459C13.5437 3.85564 11.6618 3.86827 9.35674 3.86827L5.1738 3.86827L5.04082 3.96087C4.83932 4.10819 4.75067 4.25973 4.73455 4.48703C4.72649 4.59647 4.73052 4.71854 4.74664 4.75221Z"
                  fill="white"
                />
              </svg>
              Project Masonry{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div className="portfolio-masonary-page sec-mar">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <ul className="isotope-menu">
          <li className="active" data-filter="*">
            All
          </li>
          <li data-filter=".app-development">App Development</li>
          <li data-filter=".marketing">Marketing</li>
          <li data-filter=".seo-optimize">SEO Optimize</li>
          <li data-filter=".web-development">Web development</li>
        </ul>
      </div>
    </div>
    <div className="row g-4 project-items mb-55">
      <div className="col-lg-4 col-sm-6 single-item app-development">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-12.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-12.jpg 800w, /wp-content/uploads/2023/07/Project-12-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-12-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/ut-sed-commodo-neque-morbi-erat-nisi/">
                  Ut sed commodo neque morbi erat nisi
                </a>
              </h3>
              <a href="/project-category/app-development/">
                App Development
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item app-development">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-11.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-11.jpg 800w, /wp-content/uploads/2023/07/Project-11-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-11-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/pharetra-lectus-luctus-ornare-sollicitudin/">
                  Pharetra lectus luctus ornare sollicitudin.
                </a>
              </h3>
              <a href="/project-category/app-development/">
                App Development
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item app-development">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-10.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-10.jpg 800w, /wp-content/uploads/2023/07/Project-10-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-10-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/accumsan-purus-vel-pellentesque-risus/">
                  Accumsan purus vel, pellentesque risus
                </a>
              </h3>
              <a href="/project-category/app-development/">
                App Development
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item app-development">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-9.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-9.jpg 800w, /wp-content/uploads/2023/07/Project-9-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-9-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/luctus-ornare-sollicitudin-fames/">
                  luctus ornare sollicitudin fames.
                </a>
              </h3>
              <a href="/project-category/app-development/">
                App Development
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item seo-optimize">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item" style={{}}>
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item" style={{}}>
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-8.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-8.jpg 800w, /wp-content/uploads/2023/07/Project-8-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-8-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/morbi-erat-nisi-vehicula-quis-faucibus/">
                  Morbi erat nisi, vehicula quis faucibus
                </a>
              </h3>
              <a href="/project-category/seo-optimize/">
                SEO Optimize
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item seo-optimize">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-7.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-7.jpg 800w, /wp-content/uploads/2023/07/Project-7-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-7-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/vivamus-vehicula-nl-pellentesque-quis/">
                  Vivamus vehicula nl pellentesque quis
                </a>
              </h3>
              <a href="/project-category/seo-optimize/">
                SEO Optimize
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item app-development">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-6.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-6.jpg 800w, /wp-content/uploads/2023/07/Project-6-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-6-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/sedan-pellentesque-quis-magna-eu-vestibulum/">
                  Sedan pellentesque quis magna eu vestibulum
                </a>
              </h3>
              <a href="/project-category/app-development/">
                App Development
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item marketing">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-5.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-5.jpg 800w, /wp-content/uploads/2023/07/Project-5-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-5-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/in-pharetra-lectus-luctus-ornare/">
                  In pharetra lectus luctus ornare
                </a>
              </h3>
              <a href="/project-category/marketing/">
                Marketing
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item web-development">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-4.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-4.jpg 800w, /wp-content/uploads/2023/07/Project-4-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-4-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/etiam-eu-nibh-elementum-accumsan-neque/">
                  Etiam eu nibh elementum, accumsan neque.
                </a>
              </h3>
              <a href="/project-category/web-development/">
                Web development
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item web-development">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-3.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-3.jpg 800w, /wp-content/uploads/2023/07/Project-3-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-3-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/interdum-fames-ac-ante-primis-in-faucibus/">
                  Interdum fames ac ante primis in faucibus.
                </a>
              </h3>
              <a href="/project-category/web-development/">
                Web development
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item marketing">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-2.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-2.jpg 800w, /wp-content/uploads/2023/07/Project-2-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-2-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/transforming-customer-experience/">
                  Transforming Customer Experience
                </a>
              </h3>
              <a href="/project-category/marketing/">
                Marketing
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 single-item app-development">
        <div className="magnetic-wrap">
          <div className="single-work magnetic-item">
            <div className="magnetic-wrap">
              <div className="work-img magnetic-item">
                <img
                  width={800}
                  height={560}
                  src="/wp-content/uploads/2023/07/Project-1.jpg"
                  className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="/wp-content/uploads/2023/07/Project-1.jpg 800w, /wp-content/uploads/2023/07/Project-1-300x210.jpg 300w, /wp-content/uploads/2023/07/Project-1-768x538.jpg 768w"
                  sizes="(max-width: 800px) 100vw, 800px"
                />{" "}
              </div>
            </div>
            <div className="work-content">
              <h3>
                <a href="/project/maximizing-efficiency-with-dev-ops/">
                  Maximizing Efficiency with Dev Ops
                </a>
              </h3>
              <a href="/project-category/app-development/">
                App Development
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  </div>
</div>
</div>
    )
}

export default Project
