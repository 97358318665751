import React from 'react'
import CountUp from 'react-countup';

var data = [
    {
        count: "2000+",
        title: "Projects",
        desc: "An experienced team to help implement solutions"
    },
    {
        count: "300+",
        title: "Clients",
        desc: "Our clients love us because we keep solving"
    },
    {
        count: "98%",
        title: "Success",
        desc: "We like the view from the top."
    },
    {
        count: "100%",
        title: "Commitment",
        desc: "We like the view from the top."
    },
]

const Review = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-cca9941 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="cca9941"
            data-element_type="section"
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fd4525f"
                    data-id="fd4525f"
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-b7878a5 elementor-widget elementor-widget-softconic_counter"
                            data-id="b7878a5"
                            data-element_type="widget"
                            data-widget_type="softconic_counter.default"
                        >
                            <div className="elementor-widget-container">
                                <div className="review-and-counter-area">
                                    <div className="customar-review">
                                        <h4>Our States</h4>
                                        <p>Over the years, we have achieved remarkable outcomes that have garnered global recognition and acclaim.</p>
                                    </div>
                                    <div className="counter-area">
                                        <ul>
                                            {
                                                data.map((item, index) => (
                                                    <li>
                                                        <div className="single-counter">
                                                            <div className="content">
                                                                <div className="number">
                                                                    {/* <h3 className="counter">{item.count}</h3> */}
                                                                    <CountUp className="counter-number" end={parseInt(item.count)} enableScrollSpy />+
                                                                </div>
                                                                <span>{item.title}</span>
                                                                <br />
                                                                <p>{item.desc}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Review