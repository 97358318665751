import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Planning:",
        content:
            "This initial phase involves defining the project goals, target audience, and desired outcomes.",
    },
    {
        heading: "Site Architecture:",
        content:
            "In this step, you create a logical structure for the website by designing a sitemap and organizing the content into meaningful categories.",
    },
    {
        heading: "Creation (Design and Development):",
        content:
            "This phase involves designing the visual elements and user interface (UI) of the website. It includes tasks like creating wireframes, and mockups, and selecting appropriate colors, typography, and imagery.",
    },
    {
        heading: "Testing:",
        content:
            "After the development phase, it's crucial to thoroughly test the website to ensure its functionality, compatibility, and responsiveness across different devices and browsers.",
    },
    {
        heading: "Launching:",
        content:
            "Once the website has been thoroughly tested and all issues have been resolved, it's time to deploy the website to the live server. This involves setting up hosting, configuring domains, and uploading the necessary files to make the website accessible to the public.",
    },
    {
        heading: "Maintenance:",
        content:
            "After the website is launched, ongoing maintenance is necessary to ensure its continued success. This involves monitoring the website for any issues, updating content, applying security patches, and making regular backups.",
    },
];

var features = [
    {
        heading: "Consistent Branding:",
        content:
            "Ensure that your website design aligns with your brand identity, including the use of consistent colors, typography, and visual elements. This consistency helps reinforce your brand and create a cohesive experience for users.",
    },
    {
        heading: "Distinctive Logo Placement:",
        content:
            "Place your logo prominently in the header or top section of your website. This ensures that your brand is immediately recognizable and reinforces brand association as users navigate through your site.",
    },
    {
        heading: "Unique Visual Elements:",
        content:
            "Incorporate unique visual elements that are specific to your brand. This could include custom illustrations, graphics, or photography that reflect your brand's personality and values. These elements create a memorable and distinct visual identity for your brand.",
    },
    {
        heading: "Engaging Content:",
        content:
            "Craft engaging and relevant content that captures users' attention and communicates your brand's story effectively. Use a mix of text, images, videos, and interactive elements to make your content more dynamic and appealing to your target audience.",
    },
    {
        heading: "Clear Calls to Action:",
        content:
            "Implement clear and compelling calls to action (CTAs) throughout your website. Use visually prominent buttons or links that direct users to take specific actions, such as making a purchase, signing up for a newsletter, or contacting your business",
    },
    {
        heading: "Responsive and Mobile-Friendly Design:",
        content:
            "Optimize your website for responsiveness across various devices, ensuring that it looks and functions well on mobile phones, tablets, and desktops. A mobile-friendly design is crucial, as the majority of users now access the internet through mobile devices.",
    },
    {
        heading: "Social Media Integration:",
        content:
            "Integrate social media buttons or widgets into your website, allowing users to easily connect with and share your brand on platforms like Facebook, Instagram, Twitter, and LinkedIn. This promotes brand awareness and encourages users to engage with your brand through social channels.",
    },
];
const WebDesign = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Website Design",
                    desc: "We specialize in utilizing cutting-edge technologies such as HTML, CSS, JavaScript, and JavaScript frameworks like ReactJS, AngularJS and Vue.js to build responsive, mobile-friendly and high-performance websites."
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Website Design",
                        desc: "Our website design solutions come with a range of features designed to help businesses create a strong online presence. We create a website design that reflects your brand identity and meets your business objectives. We ensure that your website is responsive across all devices, providing a seamless user experience. We design your website with user experience in mind, ensuring that it is easy to navigate and provides a positive user experience. We design e-commerce websites that are optimized for conversions and provide a seamless user experience for customers. We design websites with search engine optimization in mind to ensure that your website ranks high in search engine results pages.",
                        image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Custom Software",
                            "Enterprise Software",
                            "Web Application",
                            "Software Consulting",
                            "Mobile Application",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "We Are Working With Creative User",
                        desc: "We offer a full range of website design services designed to help businesses create a strong online presence. Our team of experienced web designers can help you design a website that is not only visually appealing but also user-friendly and responsive across all devices. We work with you to create a unique and customized website design that reflects your brand identity and meets your business objectives. We ensure that your website is responsive across all devices, including desktops, tablets, and mobile devices. We design your website with user experience in mind, ensuring that it is easy to navigate and provides a positive user experience. We design e-commerce websites that are optimized for conversions and provide a seamless user experience for customers",
                        image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }

                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Process Of Creating A Website",
                        desc: "Here is an overview of the typical process followed by software companies in creating a website",
                        data: process,
                        enddesc: "It's important to note that the website development process can vary depending on the specific project, client needs, and software company practices. However, the steps mentioned above provide a general overview of the typical process followed by software companies in creating a website."
                    },
                    unique: {
                        title: "Unique Design",
                        desc: "Create visually stunning and interactive user interfaces that engage and captivate users. Implement innovative design elements such as parallax scrolling, animated transitions, or interactive hover effects to create a memorable and immersive user experience. Develop custom illustrations and graphics that bring a distinctive and personalized touch to your designs. These can include hand-drawn icons, illustrations, or unique visual elements that align with the brand identity and create a memorable impression."
                    },
                    features: {
                        title: "Features",
                        desc: "Here are some common features of web development for software companies",
                        data: features
                    }
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default WebDesign