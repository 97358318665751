import React from 'react'

const partners = [
    {
        title: "alibaba",
        src: "/wp-content/uploads/partners/alibaba.png"
    },
    {
        title: "aws",
        src: "/wp-content/uploads/partners/aws.png"
    },
    {
        title: "google",
        src: "/wp-content/uploads/partners/google.png"
    },
    {
        title: "micro",
        src: "/wp-content/uploads/partners/micro.png"
    },
    {
        title: "sales",
        src: "/wp-content/uploads/partners/sales.png"
    },
    {
        title: "sap",
        src: "/wp-content/uploads/partners/sap.png"
    },
    {
        title: "tableau",
        src: "/wp-content/uploads/partners/tableau.png"
    },
    {
        title: "tric..",
        src: "/wp-content/uploads/partners/tric.png"
    },
    {
        title: "wins",
        src: "/wp-content/uploads/partners/wins.png"
    },
    {
        title: "google",
        src: "/wp-content/uploads/partners/google.png"
    },
    {
        title: "micro",
        src: "/wp-content/uploads/partners/micro.png"
    },
    {
        title: "aws",
        src: "/wp-content/uploads/partners/aws.png"
    },
]
const Partners = () => {
    return (
        <section
            className="elementor-section elementor-top-section elementor-element elementor-element-dfb0d4b elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="dfb0d4b"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
        >
            <div className="elementor-container elementor-column-gap-no">
                <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5658994"
                    data-id={5658994}
                    data-element_type="column"
                >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                            className="elementor-element elementor-element-ae41a5c elementor-widget elementor-widget-softconic_clients"
                            data-id="ae41a5c"
                            data-element_type="widget"
                            data-widget_type="softconic_clients.default"
                        >
                            <div className="elementor-widget-container">
                                <div className="home5-partner-area" style={{ padding: "0px" }}>
                                    <div className="container">
                                        <div className="row mb-3">
                                            <div className="col-lg-12 d-flex align-items-center justify-content-between flex-wrap gap-2">
                                                <div className="section-title-5">
                                                    <span>Partnerships</span>
                                                    <h2>Our Network</h2>
                                                </div>
                                                <div className="section-content">
                                                    <p>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="partner-wrap">
                                                    <ul>
                                                        {partners.map((item) => (
                                                            <li>
                                                                <img
                                                                    decoding="async"
                                                                    src={item.src}
                                                                    alt={item.title}
                                                                    style={{ width: "150px", height: "85px", objectFit: "contain" }}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partners