import React from 'react'
import Banner from '../services/compnents/Banner'

const MicrosoftAzure = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Microsoft Azure",
                    desc: "Our Intelligent Supplier Network (ISN) provides you the essential digital connection between buyers & suppliers for automated data-rich, fast and accurate transactions"
                }}
            />

            <section className="elementor-section elementor-top-section elementor-element elementor-element-cc9bd01 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-062c276">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-5db734c elementor-widget elementor-widget-softconic_process">
                                <div className="elementor-widget-container">
                                    <div className="section-title-4 text-center two margin-top-20">
                                        <h3>
                                            The Intelligent Enterprise starts at the digital core
                                        </h3>
                                        <img
                                            src='https://s2integrators.com/wp-content/uploads/2020/05/1-2.jpg'
                                            alt=""
                                        />

                                        <h5 className=' margin-top-20' style={{ marginBottom: 0 }}>
                                            Unbeatable offers

                                        </h5>
                                        <p style={{ padding: 0 }}>
                                            The lowest cost of ownership by combining unique pricing options and extended security updates
                                        </p>
                                        <img
                                            className=' margin-top-20'
                                            src='https://s2integrators.com/wp-content/uploads/2020/05/2-2.jpg'
                                            alt=""
                                        />

                                        <h5 className=' margin-top-20' style={{ marginBottom: 0 }}>
                                            Unmatched security
                                        </h5>
                                        <p style={{ padding: 0 }}>
                                            Intelligent security services backed by 3,500 cybersecurity professionals and the industry’s largest compliance portfolio
                                        </p>
                                        <img
                                            className=' margin-top-20'
                                            src='https://s2integrators.com/wp-content/uploads/2020/05/3-2.jpg'
                                            alt=""
                                        />

                                        <h5 className=' margin-top-20' style={{ marginBottom: 0 }}>
                                            Unparalleled innovation
                                        </h5>
                                        <p style={{ padding: 0 }}>
                                            Fully managed infrastructure and services, freeing you up to spend more time creating
                                        </p>
                                        <img
                                            className=' margin-top-20'
                                            src='https://s2integrators.com/wp-content/uploads/2020/05/3-2.jpg'
                                            alt=""
                                        />

                                        <h5 className=' margin-top-20' style={{ marginBottom: 0 }}>
                                            Built-in hybrid capabilities
                                        </h5>
                                        <p style={{ padding: 0 }}>
                                            Seamless integration of on-premises and cloud infrastructures
                                        </p>
                                        <img
                                            className=' margin-top-20'
                                            src='https://s2integrators.com/wp-content/uploads/2020/05/4-1.jpg'
                                            alt=""                                        />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MicrosoftAzure