import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Understanding your requirements:",
        content:
            "We start by gathering information about your institution, its goals, and its audience. We use this information to create a detailed project brief that outlines the scope of the project.",
    },
    {
        heading: "Design and development:",
        content:
            "Once the project brief is approved, we begin designing and developing the mobile application. We create wireframes and mockups to give you an idea of what the application will look like, and we work with you to refine the design until it meets your requirements.",
    },
    {
        heading: "Integration:",
        content:
            "We integrate third-party systems, such as online ordering and reservation systems, into the website to ensure that it meets your requirements.",
    },
    {
        heading: "Testing and launch:",
        content:
            "We test the website thoroughly to ensure that it is functioning as expected and is user-friendly. Once the website is tested and approved, we launch it and provide ongoing support and maintenance.",
    },
];

var features = [
    {
        heading: "",
        content:
            "We have a team of experienced and skilled developers and designers who are dedicated to creating exceptional educational websites.",
    },
    {
        heading: "",
        content:
            "We follow a comprehensive development process that ensures that we deliver a website that meets your requirements and provides an exceptional user experience.",
    },
    {
        heading: "",
        content:
            "We use the latest mobile application development technologies and best practices to ensure that your website is secure, scalable, and easy to use.",
    },
];
const BlockChain = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Blockchain",
                    desc: "The blockchain is one of the latest innovations in the digital world, creating a new gateway for payment that is highly transparent and secure."
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Blockchain",
                        desc: "We believe in blockchain technology's power to revolutionize businesses' operations. As a leading provider of blockchain solutions, we are dedicated to helping organizations harness the potential of this revolutionary technology to improve efficiency, transparency, and security. Our team of experts has extensive experience in developing and implementing blockchain solutions tailored to meet each business's specific needs. Whether you want to create a new blockchain application or integrate blockchain into your existing systems, we have the expertise to help you achieve your goals.",
                        image: "https://img.freepik.com/free-photo/3d-internet-secuirty-badge_1048-18106.jpg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Understanding Requirements",
                            "Design and Development",
                            "Integration",
                            "Testing and launch",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "Blackchain",
                        desc: "We understand that blockchain technology is still in its early stages and that there is a lot of uncertainty about how to utilize it best. That's why we take a strategic approach to our projects, working closely with our clients to understand their unique requirements and develop solutions that deliver real business value. With our expertise and experience, we are confident we can help you achieve your goals and drive success with blockchain technology. Let us help you unlock the full potential of blockchain technology. Contact us today to learn more about our services and how we can help you transform your business.",
                        image: "https://img.freepik.com/premium-photo/blockchain-technology-beautiful-colorful-picture-generative-ai_146671-74680.jpg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Why choose for Blockchain Development?",
                        desc: "",
                        data: [],
                        enddesc: ""
                    },
                }}
            />
            <ProcessAndFeatures
                data={{
                    process: {
                        title: "",
                        desc: "Expert Guidance:",
                        data: [],
                        enddesc: "We have a team of friendly experts at S2Integrators who know the ins and outs of complete blockchain technology. From explaining everything in simple terms to completing the entire process, we do it all."
                    },
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "",
                        desc: "Custom Solutions:",
                        data: [],
                        enddesc: "For every unique business, we develop a unique blockchain as per your business needs. We create a blockchain, which makes your data super secure."
                    },
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "",
                        desc: "Latest Technology:",
                        data: [],
                        enddesc: "S2Integrators is always up to date with the latest technology in blockchain, and we ensure that we use the latest technology in your project for highly secure data for the modern world."
                    },
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "",
                        desc: "We do it all:",
                        data: [],
                        enddesc: "We as the best blockchain development company plan everything. After thorough testing, we hand out the blockchain project to our clients."
                    },
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default BlockChain