import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Technical Support:",
        content:
            "At S2Integrators, we understand the importance of providing excellent technical support to our clients. Our team of experienced technicians is available to assist you with any technical issues you may be experiencing with your website, software or hardware.",
    },
    {
        heading: "Application Improvement:",
        content:
            "Our experienced developers are dedicated to helping you improve your existing applications. We offer various services, including performance optimization, feature enhancements, and user experience improvements. We'll work closely with you to understand your needs and deliver solutions that help you achieve your goals.",
    },
    {
        heading: "Steady Monitoring of the Software:",
        content:
            "At S2Integrators, we offer regular software monitoring to ensure it runs smoothly and efficiently. We understand the importance of uptime and reliability in today's fast-paced business environment, and our team is dedicated to providing our clients with the highest level of service to our clients.",
    },
    {
        heading: "Product Enhancement:",
        content:
            "At S2Integrators, we understand the importance of staying competitive in today's fast-paced business environment. That's why we offer product enhancement services to help our clients stay ahead of the curve.",
    },
];

var features = [
    {
        heading: "Automation:",
        content:
            "Say goodbye to the regular tasks and start using AI to automate your business so that your team can focus more on any important work, making your business more successful.",
    },
    {
        heading: "Smart Insights:",
        content:
            "AI understands your customer behavior and makes better decisions using the data from AI insights.",
    },
    {
        heading: "Cost Saving:",
        content:
            "AI can save your costs in the long run and improve efficiency.",
    },
];
const TechnicalSupport = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Technical Support",
                    desc: "We can keep working on new software features, handle your questions and emerging issues, and develop and implement improvements"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Technical Support",
                        desc: "At S2Integrators, we offer comprehensive technical support services to ensure the seamless operation of your technology systems. Our team of experienced technicians is available 24/7 to help you resolve any technical issues you may encounter. Our technical support team provides comprehensive services to ensure your technology systems function smoothly. Our team is composed of experienced and knowledgeable professionals who are dedicated to providing top-notch support. We offer services such as troubleshooting technical issues, software installation, hardware maintenance, and security updates. Our goal is to minimize downtime and maximize productivity by providing quick and practical solutions to any technical issues that may arise. Contact us for reliable and prompt technical support services.",
                        image: "https://img.freepik.com/free-photo/person-working-html-computer_23-2150038860.jpg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Understanding Requirements",
                            "Design and Development",
                            "Integration",
                            "Testing and launch",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "Technical Support",
                        desc: "At S2Integrators, we offer comprehensive technical support services to ensure the seamless operation of your technology systems. Our team of experienced technicians is available 24/7 to help you resolve any technical issues you may encounter. Our technical support team provides comprehensive services to ensure your technology systems function smoothly. Our team is composed of experienced and knowledgeable professionals who are dedicated to providing top-notch support. We offer services such as troubleshooting technical issues, software installation, hardware maintenance, and security updates. Our goal is to minimize downtime and maximize productivity by providing quick and practical solutions to any technical issues that may arise. Contact us for reliable and prompt technical support services.",
                        image: "https://img.freepik.com/free-photo/confident-call-center-operator-talking-with-client_74855-4059.jpg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "",
                        desc: "",
                        data: process,
                        enddesc: ""
                    },
                }}
            />


            <OurCoreFeautres />
        </div>
    )
}

export default TechnicalSupport