import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Small and Medium-sized Businesses (SMBs):",
        content:
            "SMBs can leverage CRM software to streamline their customer management processes, enhance customer service, and improve sales and marketing efforts. CRM helps SMBs organize customer data, track interactions, and manage leads and opportunities effectively.",
    },
    {
        heading: "Sales Teams:",
        content:
            "Sales teams can utilize CRM to track and manage their leads, prospects, and deals. CRM systems provide a centralized platform to store customer information, track sales activities, manage pipelines, and generate sales reports. This helps sales representatives prioritize their efforts, improve collaboration, and close deals more efficiently.",
    },
    {
        heading: "Marketing Departments:",
        content:
            "CRM can be valuable for marketing departments to manage customer data, segment audiences, and execute targeted marketing campaigns. With CRM, marketers can track customer preferences, send personalized communications, measure campaign effectiveness, and optimize marketing strategies based on data-driven insights.",
    },
    {
        heading: "E-commerce Businesses:",
        content:
            "CRM can be beneficial for e-commerce businesses to track customer behaviors, preferences, and purchase history. This data helps optimize personalized product recommendations, targeted marketing campaigns, and post-purchase communication, ultimately improving customer retention and driving repeat sales.",
    },
    {
        heading: "B2B (Business-to-Business) Organizations:",
        content:
            "B2B companies can use CRM to manage complex sales cycles, track interactions with multiple stakeholders, and nurture long-term relationships. CRM provides visibility into the sales process, facilitates collaboration among team members, and helps identify cross-selling or upselling opportunities.",
    },
    {
        heading: "Nonprofit Organizations:",
        content:
            "CRM can assist nonprofit organizations in managing donor relationships, tracking fundraising efforts, and coordinating volunteer activities. CRM systems enable nonprofits to segment donors, personalize communications, and track campaign success, helping to build stronger relationships and maximize donor contributions.",
    },
    {
        heading: "Professional Services Providers:",
        content:
            "Professionals such as consultants, lawyers, or accountants can utilize CRM to manage client relationships, track project progress, and streamline communication. CRM helps professionals stay organized, deliver excellent service, and maintain long-term client satisfaction.",
    },
];

var features = [
    {
        heading: "Contact Management:",
        content:
            "CRM systems provide a centralized database for storing and organizing customer contact information. This includes details like names, email addresses, phone numbers, job titles, and other relevant data. Contact management features allow users to easily add, edit, and update customer information.",
    },
    {
        heading: "Lead and Opportunity Management:",
        content:
            "CRM helps track and manage leads, allowing businesses to capture and qualify potential customers. CRM systems provide tools to assign leads to sales representatives, track their progress through the sales pipeline, and convert them into opportunities. This feature helps optimize lead conversion and maximize sales opportunities.",
    },
    {
        heading: "Sales and Deal Tracking:",
        content:
            "CRM platforms often include features to track sales activities, deals, and revenue. Sales teams can record interactions with customers, track deal stages, set reminders for follow-ups, and generate sales forecasts and reports. This helps streamline sales processes, improve collaboration, and measure sales performance.",
    },
    {
        heading: "Customer Communication and Interaction Tracking:",
        content:
            "CRM systems enable businesses to record and track customer interactions, such as emails, phone calls, meetings, and support tickets. This allows teams to maintain a comprehensive history of customer communication, providing insights into customer preferences, needs, and interactions for better engagement.",
    },
    {
        heading: "Task and Activity Management:",
        content:
            "CRM platforms offer task and activity management features, allowing users to create and assign tasks, set reminders, and track progress. This helps teams stay organized, manage deadlines, and ensure that important customer-related tasks are completed promptly.",
    },
    {
        heading: "Reporting and Analytics:",
        content:
            "CRM systems provide reporting and analytics capabilities, allowing businesses to gain insights into their customer data. Users can generate reports on sales performance, customer segmentation, campaign effectiveness, and other key metrics. Analyzing this data helps make informed business decisions, identify trends, and measure the success of marketing and sales efforts.",
    },
    {
        heading: "Marketing Automation:",
        content:
            "Many CRM platforms offer marketing automation features to streamline marketing processes. This includes tools for email marketing, campaign management, lead nurturing, and segmentation. Automation features help businesses deliver targeted and personalized marketing messages, track campaign performance, and automate repetitive marketing tasks.",
    },
];
const CRM = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "CRM Software Development",
                    desc: "We can integrate your existing business software, such as accounting software, email marketing software, or inventory management software, with your CRM system."
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "CRM Software Development",
                        desc: "We specialize in creating customized CRM solutions that can help your business improve customer relationships, increase sales, and streamline business operations. Here is some information about our CRM software development services and how we can help your business achieve success. We create customized CRM solutions that are tailored to meet the specific needs of your business. We can integrate your existing business software, such as accounting software, email marketing software, or inventory management software, with your CRM system. We can migrate your existing customer data from your current CRM system to a new CRM system. We provide ongoing maintenance and support services to ensure that your CRM system remains up-to-date and functional.",
                        image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Custom Software",
                            "Enterprise Software",
                            "Web Application",
                            "Software Consulting",
                            "Mobile Application",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "Why S2Integrators for CRM Software Development?",
                        desc: "We specializes in developing custom CRM solutions tailored to the unique needs of businesses. They have a team of experienced software engineers who possess in-depth knowledge and expertise in CRM development. By understanding your specific requirements and business processes, S2Integrators can create a CRM system that perfectly aligns with your organization's goals and objectives. S2Integrators excels in full-stack development, covering both front-end and back-end technologies. Their team is proficient in a wide range of programming languages, frameworks, and databases, ensuring that your CRM software is built with the latest and most suitable technologies. Whether it's frontend interfaces, complex backend functionalities, or seamless integration with other systems, S2Integrators has the capabilities to deliver top-notch solutions.",
                        image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }

                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Who Can Use CRM?",
                        desc: "Here is an overview of the typical process followed by software companies in creating a website",
                        data: process,
                        enddesc: ""
                    },
                    unique: {
                        title: "",
                        desc: ""
                    },
                    features: {
                        title: "Features",
                        desc: "CRM (Customer Relationship Management) software typically offers a range of features that help businesses manage and enhance their interactions with customers.",
                        data: features
                    }
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default CRM