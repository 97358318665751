import React from 'react'

const Banner = ({ data }) => {
    return (
        <div className="aboutus-container">
            <div className="">
                <div className="full-height-bg-content"
                    style={{
                        background: `url(${data.bgImage}) no-repeat 0 100%`,
                        backgroundPosition: "50%",
                        backgroundSize: "cover"
                    }}>
                    <div className="container">
                        <h2>{data.title}</h2>
                        <div className="mx-auto">
                            <p>
                                {data.desc}
                            </p>
                            <div className="mt-3 d-none">
                                <a
                                    className="learn-more-white-revamp"
                                    href="/"
                                >
                                    SAP Foundation
                                </a>
                                <br />
                                <a
                                    className="learn-more-white-revamp"
                                    href=""
                                    target="_blank"
                                >
                                    SAP Sustainability Annual Report 2023
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner