import React, { useState } from 'react'

const Header = () => {

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  var serviceroutes = [
    "/sap-ariba",
    "/sap-consulting",
    "/sap-hana-consulting",
    "/mobile-development",
    "/web-development",
    "/web-design",
    "/crm-software-development",
    "/block-chain",
    "/ai-ml",
    "/internet-of-things",
    "/data-analytics",
    "/cloud-computing",
    "/digital-marketing",
    "/technical-support",
    "/microsoft-consulting",
    "/snowflake-partnership"
  ]
  var productroutes = [
    "/req-2-source",
    "/intelligent-supplier-network-isn",
    "/s-4hana-cloud",
    "/microsoft-azure"
  ]

  var servicedata = [
    {
      title: "SAP",
      data: [
        {
          title: "SAP Ariba",
          link: "/sap-ariba"
        },
        {
          title: "SAP Consulting",
          link: "/sap-consulting"
        },
        {
          title: "SAP HANA Consulting",
          link: "/sap-hana-consulting"
        },
      ]
    },
    {
      title: "Software Developement",
      data: [
        {
          title: "Mobile Developement",
          link: "/mobile-development"
        },
        {
          title: "Web Developement",
          link: "/web-development"
        },
        {
          title: "web design",
          link: "/web-design"
        },
        {
          title: "CRM",
          link: "/crm-software-development"
        },
      ]
    },
    {
      title: "Latest Thinking",
      data: [
        {
          title: "Blockchain",
          link: "/block-chain"
        },
        {
          title: "Ai & ML",
          link: "/ai-ml"
        },
        {
          title: "Internet of Things",
          link: "/internet-of-things"
        },
        {
          title: "Data Analytics",
          link: "/data-analytics"
        },
        {
          title: "Cloud Computing",
          link: "/cloud-computing"
        },
        {
          title: "Digtal Market",
          link: "/digital-marketing"
        },
        {
          title: "Technical Support",
          link: "/technical-support"
        },
      ]
    },
    {
      title: "Others",
      data: [
        {
          title: "Microsoft Consulting",
          link: "/microsoft-consulting"
        },
        {
          title: "Snowflake Partnership",
          link: "/snowflake-partnership"
        },
      ]
    },
  ]

  var productsdata = [
    {
      title: "Req2Source",
      link: "req-2-source"
    },
    {
      title: "Intelligent Supplier Network",
      link: "intelligent-supplier-network-isn"
    },
    {
      title: "S/4HANA Cloud",
      link: "s-4hana-cloud"
    },
    {
      title: "Microsoft Azure",
      link: "microsoft-azure"
    },
  ]

  var [serviceSelected, setServiceSelected] = useState(false);
  var [productSelected, setProductSelected] = useState(false);

  const [activeTab, setActiveTab] = useState("SAP");

  const menuItems = [
    {
      title: "SAP",
      href: "/services/sap",
      subItems: [
        {
          title: "SAP Ariba",
          iconSrc: "assets/images/services/icon/sap-arib.png",
          href: "/sap-ariba"
        },
        {
          title: "SAP Consulting",
          iconSrc: "assets/images/services/icon/sap-consulting.png",
          href: "/sap-consulting"
        },
        {
          title: "SAP HANA Consulting",
          iconSrc: "assets/images/services/icon/sap-hana.png",
          href: "/sap-hana-consulting"
        }
      ]
    },
    {
      title: "Software Developement",
      href: "/services/software-developement",
      subItems: [
        {
          title: "Mobile Developement",
          iconSrc: "assets/images/services/icon/mobile-dev.png",
          href: "/mobile-development"
        },
        {
          title: "Web Developement",
          iconSrc: "assets/images/services/icon/web-dev.png",
          href: "/web-development"
        },
        {
          title: "web design",
          iconSrc: "assets/images/services/icon/web-design.png",
          href: "/web-design"
        },
        {
          title: "CRM",
          iconSrc: "assets/images/services/icon/crm.png",
          href: "/crm-software-development"
        }
      ]
    },
    {
      title: "Latest Thinking",
      href: "/services/latest-thinking",
      subItems: [
        {
          title: "Blockchain",
          iconSrc: "assets/images/services/icon/blockchain.png",
          href: "/block-chain"
        },
        {
          title: "Ai & ML",
          iconSrc: "assets/images/services/icon/aiml.png",
          href: "/ai-ml"
        },
        {
          title: "Internet of Things",
          iconSrc: "assets/images/services/icon/iot.png",
          href: "/internet-of-things"
        },
        {
          title: "Data Analytics",
          iconSrc: "assets/images/services/icon/data-analys.png",
          href: "/data-analytics"
        },
        {
          title: "Cloud Computing",
          iconSrc: "assets/images/services/icon/cloud.png",
          href: "/cloud-computing"
        },
        {
          title: "Digtal Market",
          iconSrc: "assets/images/services/icon/digital-market.png",
          href: "/digital-marketing"
        },
        {
          title: "Technical Support",
          iconSrc: "assets/images/services/icon/technical-support.png",
          href: "/technical-support"
        }
      ]
    },
    {
      title: "Others",
      href: "/services/others",
      subItems: [
        {
          title: "Microsoft Consulting",
          iconSrc: "assets/images/services/icon/microsoft.png",
          href: "/microsoft-consulting"
        },
        {
          title: "Snowflake Partnership",
          iconSrc: "assets/images/services/icon/snowflake.png",
          href: "/snowflake-partnership"
        }
      ]
    }
  ]


  const formattedData = servicedata.map((item) => ({
    title: item.title,
    iconSrc: `https://www.arkasoftwares.com/images/navbar-icon/new-menu-icons/${item.title.toLowerCase().replace(/\s+/g, '-')}-icon.svg`,
    href: `/services/${item.title.toLowerCase().replace(/\s+/g, '-')}`,
    subItems: item.data.map((subItem) => ({
      title: subItem.title,
      iconSrc: "https://www.arkasoftwares.com/images/navbar-icon/new-menu-icons/power-bi-icon.svg",
      href: subItem.link,
    })),
  }));

  console.log(formattedData);


  return (
    <header className="header-area2 style-2 two" style={{
      position: "fixed",
      backgroundColor: "#23212178"
    }}>
      <div className="header-logo">
        <a href="" title="S2Integrators">
          <img
            className="img-fluid"
            src="/wp-content/uploads/logo/s2logo.png"
            alt="S2Integrators"
          />
        </a>
      </div>

      <div className={`main-menu ${isMenuOpen ? "show-menu" : ""}`}>
        <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
          <div className="mobile-logo-wrap">
            <a href="" title="S2Integrators">
              <img
                className="img-fluid"
                src="/wp-content/uploads/logo/s2logo.png"
                // src="/wp-content/plugins/softconic-core/inc/theme-options/images/logo/logo.svg"
                alt="S2Integrators"
                style={{ width: "100px" }}
              />
            </a>
          </div>
        </div>
        <div className="main-menu-box">
          <ul className="menu-list">
            <li className={"menu-item menu-item-type-custom menu-item-object-custom menu-item-home " + (window.location.pathname == "/" ? "current-menu-ancestor current-menu-parent" : "")}>
              <a href="/">Home</a>
              <i className="bi bi-plus dropdown-icon" />
            </li>

            {/* Service Menu Container*/}
            <li className={"service-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children " + (serviceroutes.includes(window.location.pathname) ? "current-menu-ancestor" : "")}>
              <a href="/">Services</a>
              <i className="bi bi-plus dropdown-icon" onClick={() => { setServiceSelected(!serviceSelected) }} />
              <ul className="sub-menu service-desktop service-menu-container" style={{ width: "max-content" }}>
                <div className="container">
                  <div className="row">
                    {/* Nav tabs */}
                    <div
                      className="col-lg-5 ps-0 nav"
                      role="tablist"
                      style={{ background: "#f6deb2", padding: 0 }}>
                      <div
                        className="menu-internal-tab"
                        style={{ width: "100%" }}
                      >
                        {menuItems.map((item, index) => (
                          <div
                            key={index}
                            className={`nav-item ${index === 0 ? "active" : ""}`}
                            style={{
                              margin: "10px 0px 10px 10px",
                              background: activeTab === item.title ? "white" : "transparent"
                            }}
                          >
                            <a
                              role="tab"
                              id={`tab-${index}`}
                              aria-controls={`tabpane-${index}`}
                              aria-selected={activeTab === item.title}
                              className={`nav-link ${activeTab === item.title ? "active" : ""}`}
                              onMouseEnter={() => setActiveTab(item.title)}
                              // onMouseLeave={() => setActiveTab("/service/sap")}
                              style={{ color: "black", width: "100%", padding: "20px 14px" }}
                            >
                              {item.title}
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Tab panes */}
                    <div className="col-lg-7 tab-content">
                      {menuItems.map((item, index) => (
                        <div
                          key={index}
                          role="tabpanel"
                          id={`tabpane-${index}`}
                          aria-labelledby={`tab-${index}`}
                          className={`tab-pane fade ${activeTab === item.title ? "show active" : ""}`}
                        >
                          {/* Tab header */}
                          <div className="page-head-menu">
                            <a className="nav-spacingcls flex-headCls" href={item.href} style={{ padding: "10px", fontSize: "20px" }}>
                              <figcaption style={{ color: "black" }}>{item.title}</figcaption>
                            </a>
                          </div>

                          {/* Sub-menu items */}
                          <ul className="sub-menu-last new-menu-list" style={{ maxHeight: "60vh", overflow: "scroll" }}>
                            {item.subItems.map((subItem) => (
                              <li key={subItem.title} style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                                <img src={subItem.iconSrc} alt={item.title} style={{ width: "50px", height: "50px" }} />
                                <a className="nav-spacingcls flex-headCls" href={subItem.href} style={{ color: "black", padding: "10px" }}>
                                  {subItem.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </ul>

              <ul className="sub-menu service-mobile" style={{ display: serviceSelected ? "block" : "none" }}>
                {servicedata.map((item) => (
                  <li className="menu-item menu-item-type-custom menu-item-object-custom">
                    <a>{item.title}</a>
                    {item.data.map((item) => (
                      <a href={item.link} style={{ borderBottom: "none", marginLeft: "10px" }}>{item.title}</a>
                    ))} </li>
                ))}
              </ul>
            </li>

            <li className={"menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children " + (productroutes.includes(window.location.pathname) ? "current-menu-ancestor" : "")}>
              <a href="/">Products </a>
              <i className="bi bi-plus dropdown-icon" onClick={() => { setProductSelected(!productSelected) }} />
              <ul className="sub-menu product-menu" style={{ display: productSelected ? "block" : "none" }}>
                {productsdata.map((item) => (
                  <li className="menu-item menu-item-type-custom menu-item-object-custom">
                    <a href={item.link}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </li>

            <li className={"menu-item menu-item-type-custom menu-item-object-custom " + (window.location.pathname == "/blog" ? "current-menu-ancestor" : "")}>
              <a href="/blog">Blogs</a>
              <i className="bi bi-plus dropdown-icon" />
            </li>

            <li className={"menu-item menu-item-type-post_type menu-item-object-page " + (window.location.pathname == "/careers" ? "current-menu-ancestor" : "")} >
              <a href="#">Careers</a>
              <i className="bi bi-plus dropdown-icon" />
            </li>

            <li className={"menu-item menu-item-type-post_type menu-item-object-page " + (window.location.pathname == "/about" ? "current-menu-ancestor" : "")}>
              <a href="/about">About us</a>
            </li>

            <li className={"menu-item menu-item-type-post_type menu-item-object-page " + (window.location.pathname == "/contactus" ? "current-menu-ancestor" : "")}>
              <a href="/contactus">Contact us</a>
            </li>
          </ul>
        </div>
        <div className="d-lg-none d-block">
          <form className="mobile-menu-form">
            <div className="hotline pt-30">
              <div className="hotline-icon">
                <img
                  src="/wp-content/plugins/softconic-core/inc/theme-options/images/mobile-menu/phone-icon.svg"
                  alt=""
                  style={{ filter: "hue-rotate(237deg)" }}
                />
              </div>
              <div className="hotline-info">
                <span>Call Us Now</span>
                <h6>
                  <a href="tel:+1 (678) 784 4155">+1 (678) 784 4155</a>
                </h6>
              </div>
            </div>
            <div className="email pt-20 d-flex align-items-center">
              <div className="email-icon">
                <img
                  src="/wp-content/plugins/softconic-core/inc/theme-options/images/mobile-menu/email-icon.svg"
                  alt=""
                  style={{ filter: "hue-rotate(237deg)" }}
                />
              </div>
              <div className="email-info">
                <span>Email Now</span>
                <h6>
                  <a href="mailto:info@qcodesinfotech.com">info@qcodesinfotech.com</a>
                </h6>
              </div>
            </div>
          </form>
          <div className="header-btn5">
            <a className="primary-btn3" href="">
              Get A Quote
            </a>
          </div>
        </div>
      </div>

      <div className="nav-right d-flex jsutify-content-end align-items-center">
        <div className="header-contact d-xl-block d-none">
          <span>
            <img
              src="/wp-content/uploads/elementor/img/tel.png"
              alt=""
              style={{ width: "18px" }}
            />
            For Client Support:{" "}
          </span>
          <h6>
            <a href="tel:+1 (678) 784 4155">+1 (678) 784 4155</a>
          </h6>
        </div>
        <div className="header-btn d-sm-flex d-none">
          <a href="">Get A Quote </a>
        </div>
        <div className="sidebar-button mobile-menu-btn " onClick={toggleMenu}>
          <span />
        </div>
      </div>
    </header>

  )
}

export default Header