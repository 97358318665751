import React from 'react'


const data = [
    { name: "Java Script", source: "https://qcodesinfotech.com/images/service/newicons/javascript.webp" },
    { name: "HTML", source: "https://qcodesinfotech.com/images/service/webdevp/HTML.webp" },
    { name: "Node JS", source: "https://qcodesinfotech.com/images/service/webdevp/node.webp" },
    { name: "Python", source: "https://qcodesinfotech.com/images/service/webdevp/python.webp" },
    { name: "PHP", source: "https://qcodesinfotech.com/images/service/webdevp/php2.webp" },
    { name: "MySQL", source: "https://qcodesinfotech.com/images/service/webdevp/mysql.webp" },
    { name: "MongoDB", source: "https://qcodesinfotech.com/images/service/webdevp/mongo.webp" },
    { name: "Firebase", source: "https://qcodesinfotech.com/images/service/webdevp/firebase.webp" },
    { name: "Ajax", source: "https://qcodesinfotech.com/images/service/webdevp/ajax.webp" },
    { name: "React", source: "https://qcodesinfotech.com/images/service/webdevp/react.webp" },
    { name: "CSS", source: "https://qcodesinfotech.com/images/service/webdevp/css.webp" },
    { name: "Laravel", source: "https://qcodesinfotech.com/images/service/webdevp/larvel.webp" },
];
const OurCoreFeautres = () => {
    return (
        <section className="col-12 margin-top-20">
            <div className="container pb-5" style={{ width: "80%" }}>
                <div className="row justify-content-md-center text-center mb-4">
                    <div className="p-0">
                        <div className="section-title text-center">
                            <h1 className="feature-title box-title mb-3 text-white">
                                Our Core Features
                            </h1>
                            <span className="text-white">
                                If the base is strong, the building is robust. Have a glance
                                at the core features that makes our base firm!
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-12 p-0">
                        <div className="category core-container category-grid-style-01">
                            {data.map((item, index) => (
                                <div className="category-item core-item">
                                    <div className="category-icon">
                                        <img
                                            className="img-fuild"
                                            style={{ height: "100px", width: "100%" }}
                                            src={item.source}
                                            alt={item.name}
                                        />
                                    </div>
                                    <a className="category-title">{item.name}</a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurCoreFeautres