import logo from './logo.svg';
import './App.css';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Service from './components/services/Service';
import ServiceDetail from './components/services/ServiceDetail';
import ContactUs from './components/contact/Contactus';
import Career from './components/career/career';
import Blog from './components/blog/Blog';
import About from './components/about/About';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header'
import Product from './components/project/Project'
import MobileDevelopment from './components/services/software/MobileDevelopment';
import WebDevelopment from './components/services/software/WebDevelopment';
import WebDesign from './components/services/software/WebDesign';
import CRM from './components/services/software/CRM';
import SapAriba from './components/services/sap/SapAriba';
import SapConsulting from './components/services/sap/SapConsulting';
import SapHana from './components/services/sap/SapHana';
import Req2Source from './components/products/Req2Source';
import IntelligentSupplier from './components/products/IntelligentSupplier';
import HanaCloud from './components/products/HanaCloud';
import MicrosoftAzure from './components/products/MicrosoftAzure';
import MicrosoftConsulting from './components/services/others/MicrosoftConsulting';
import SnowflakePartnership from './components/services/others/SnowflakePartnership';
import BlockChain from './components/services/latestthinking/BlockChain';
import AiMl from './components/services/latestthinking/AiMl';
import InternetOfThings from './components/services/latestthinking/InternetOfThings';
import DataAnalytics from './components/services/latestthinking/DataAnalytics';
import CloudCoumputing from './components/services/latestthinking/CloudCoumputing';
import DigitalMarketing from './components/services/latestthinking/DigitalMarketing';
import TechnicalSupport from './components/services/latestthinking/TechnicalSupport';

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/service" element={<Service />} />
          <Route path="/service/:id" element={<ServiceDetail />} />

          {/* services */}
          <Route path="/sap-ariba" element={<SapAriba />} />
          <Route path="/sap-consulting" element={<SapConsulting />} />
          <Route path="/sap-hana-consulting" element={<SapHana />} />

          <Route path="/mobile-development" element={<MobileDevelopment />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/web-design" element={<WebDesign />} />
          <Route path="/crm-software-development" element={<CRM />} />

          <Route path="/block-chain" element={<BlockChain />} />
          <Route path="/ai-ml" element={<AiMl />} />
          <Route path="/internet-of-things" element={<InternetOfThings />} />
          <Route path="/data-analytics" element={<DataAnalytics />} />
          <Route path="/cloud-computing" element={<CloudCoumputing />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/technical-support" element={<TechnicalSupport />} />

          <Route path="/microsoft-consulting" element={<MicrosoftConsulting />} />
          <Route path="/snowflake-partnership" element={<SnowflakePartnership />} />



          {/* products */}
          <Route path="/req-2-source" element={<Req2Source />} />
          <Route path="/intelligent-supplier-network-isn" element={<IntelligentSupplier />} />
          <Route path="/s-4hana-cloud" element={<HanaCloud />} />
          <Route path="/microsoft-azure" element={<MicrosoftAzure />} />


          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/product" element={<Product />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
