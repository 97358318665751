import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Customized Solutions:",
        content:
            "We understands clearly that every business is unique in its own way. That’s why we try to understand the function of your business before creating an AI tool for it. We create AI solutions based on your needs, such as making your regular tasks automatic, understanding your customer behavior, and many more.",
    },
    {
        heading: "Real Results:",
        content:
            "Using AI for your business can be game-changing, and it provides real-time results without any delay. Whether it be chatbots or predictions, AI can help you out in fractions of seconds. We has many years of proven experience in turning your AI concepts into reality. We believe that our team of experts in AI can help you in your transformation journey.",
    },
    {
        heading: "Easy Integration:",
        content:
            "As a leading AI and ML company, we believe that complete AI integration is necessary, and we do not only provide the AI product; we also make sure that it works and fits in your existing systems. S2Integrators gives complete control of the AI system to its clients, and we provide details and necessary training with regular support.",
    },
];

var features = [
    {
        heading: "Automation:",
        content:
            "Say goodbye to the regular tasks and start using AI to automate your business so that your team can focus more on any important work, making your business more successful.",
    },
    {
        heading: "Smart Insights:",
        content:
            "AI understands your customer behavior and makes better decisions using the data from AI insights.",
    },
    {
        heading: "Cost Saving:",
        content:
            "AI can save your costs in the long run and improve efficiency.",
    },
];
const CloudCoumputing = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Cloud Computing",
                    desc: "Cloud computing allows businesses to store, manage, and access their data and applications through the Internet without worrying about the cost and complexity of managing and maintaining their infrastructure"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Cloud Computing",
                        desc: "At S2Integrators, we specialize in providing businesses with comprehensive cloud computing solutions that meet their needs. Our team of experts deeply understands the latest cloud technologies and works closely with our clients to understand their requirements and deliver solutions tailored to their specific needs.",
                        image: "https://img.freepik.com/free-photo/woman-scrolling-laptop_53876-167050.jpg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Amazon Web Service",
                            "Google Cloud",
                            "Azure",
                            "Oracle",
                            "VMware Cloud"
                        ]
                    },
                    sessionTwo: {
                        title: "Cloud Computing",
                        desc: "Our services include cloud migrations, strategy consulting, infrastructure setup and management, and security. Whether you're looking to move your existing data and applications to the cloud or starting from scratch, we have the expertise to help you make the most of this exciting technology. Our cloud computing solutions are designed to be flexible, scalable, and secure, so you can focus on growing your business without worrying about your technology.",
                        image: "https://img.freepik.com/premium-photo/man-holding-virtual-cloud-computing-hand-with-connection-line_50039-1918.jpg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }
                }}
            />


            <OurCoreFeautres />


        </div>
    )
}

export default CloudCoumputing