import React from 'react'
import Banner from '../services/compnents/Banner'

const IntelligentSupplier = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Intelligent Supplier Network (ISN) – Powered by SAP Ariba",
                    desc: "Our Intelligent Supplier Network (ISN) provides you the essential digital connection between buyers & suppliers for automated data-rich, fast and accurate transactions"
                }}
            />

            <section className="elementor-section elementor-top-section elementor-element elementor-element-cc9bd01 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-062c276">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-5db734c elementor-widget elementor-widget-softconic_process">
                                <div className="elementor-widget-container">
                                    <div className="section-title-4 text-center two margin-top-20">
                                        <h3>
                                            Accelerated path to SAP Digital Supplier Network(DSN)
                                        </h3>

                                        <p className='text-start margin-top-20'>
                                            Companies operate in highly regulated compliance, data, and document-intensive environments. The key challenge is to automate the documentation process in a seamless, touchless manner.
                                        </p>
                                        <p className='text-start margin-top-20'>
                                            Our Intelligent Supplier Network (ISN) provides you the essential digital connection between buyers & suppliers for automated data-rich, fast and accurate transactions. It is the foundation for any company looking to digitize their procurement, finance & supply chain.
                                        </p>

                                        <img
                                            alt=""
                                            className='margin-top-20'
                                            src='https://s2integrators.com/wp-content/uploads/2020/09/ISN-IMG-1536x607.jpg'
                                        />

                                        <h3 className='text-start margin-top-20 color-primary'>
                                            Procurement
                                        </h3>

                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <h5 className='text-start'>
                                                    Challenges
                                                </h5>
                                                <p className='text-start'>
                                                    - Many procurement teams use discrete tools and systems to manage their source-to-pay (S2P) operations and supplier processes. This creates frustrating barriers among the sourcing, contracting, purchasing, and accounts payable teams.
                                                </p>
                                                <p className='text-start'>
                                                    - Procurement inefficiencies increase compliance issues, negotiation disputes with supplies, and cost pressures, which have a cascading effect on the organization’s bottom line.
                                                </p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <h5 className='text-start'>
                                                    Solutions
                                                </h5>

                                                <p className='text-start'>
                                                    - ISN helps to enable to the digitalization of suppliers wit Many-to-many collaboration.
                                                </p>
                                                <p className='text-start'>
                                                    - ISN helps the suppliers to reduce 2% spend within a short period of time and have better visibility on the transactional flows.
                                                </p>
                                            </div>

                                        </div>

                                        <h3 className='text-start margin-top-20 color-primary'>
                                            Finance
                                        </h3>

                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <h5 className='text-start'>
                                                    Challenges
                                                </h5>
                                                <p className='text-start'>
                                                    - Accounts payable clerks have the challenge of managing more than 5k invoices in a year which are stone-age procurement practices: error-prone, and labor-intensive.
                                                </p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <h5 className='text-start'>
                                                    Solutions
                                                </h5>

                                                <p className='text-start'>
                                                    - Having ISN will help manage up to 50k invoices / year, which is 10x efficient than the manual invoicing.
                                                </p>
                                                <p className='text-start'>
                                                    - It enables finance departments to be more strategic when managing cash flow, taking advantage of negotiated discounts.
                                                </p>
                                            </div>
                                        </div>

                                        <h3 className='text-start margin-top-20 color-primary'>
                                            Information Technology
                                        </h3>

                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <h5 className='text-start'>
                                                    Challenges
                                                </h5>
                                                <p className='text-start'>
                                                    - The challenge of automating the procurement management process and suppliers has been a longstanding problem. Such manual and paper-driven processes will slow down and hinder the best procurement practices.
                                                </p>
                                                <p className='text-start'>
                                                    - The business case for SAP S/4HANA alone is sometimes not compelling enough because of slow value realization, and increased infrastructure.
                                                </p>
                                            </div>
                                            <div className='col-lg-6'>
                                                <h5 className='text-start'>
                                                    Solutions
                                                </h5>

                                                <p className='text-start'>
                                                    - ISN aids the Head of Digital to drive digital transformation to optimize the suppliers, avoiding inaccurate and missing consents, reducing the PD error rate by creating digitized procurement documents, and simplifying the process across platforms and markets.
                                                </p>
                                                <p className='text-start'>
                                                    - ISN enables users to get on the path of adoption quickly to leverage the network’s business rules to facilitate 100% touchless transactions, increasing process compliance and eliminating errors.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default IntelligentSupplier