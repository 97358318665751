import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Define your goals and objectives:",
        content:
            "Determine the purpose of your website and what you want to achieve with it. Identify your target audience, key features, and functionality requirements.",
    },
    {
        heading: "Plan and research:",
        content:
            "Conduct thorough research on your target audience, competitors, and industry trends. Create a sitemap to outline the structure and navigation of your website. Plan the content and gather any necessary resources such as images, videos, and text.",
    },
    {
        heading: "Choose a development platform:",
        content:
            "Select a suitable platform for building your website. Some popular options include WordPress, Drupal, Joomla, Shopify, or custom development using HTML, CSS, and JavaScript.",
    },
    {
        heading: "Design the user interface (UI):",
        content:
            "Create wireframes or mockups to visualize the layout and design of your website. Consider the user experience (UX) by ensuring intuitive navigation, responsive design for different devices, and accessibility for all users.",
    },
    {
        heading: "Develop the website:",
        content:
            "Start implementing the design by coding the website using the chosen platform or programming languages. This involves writing HTML/CSS for the structure and styling, JavaScript for interactivity, and backend development for dynamic functionality such as forms, databases, and content management systems (CMS).",
    },
    {
        heading: "Content creation:",
        content:
            "Write and optimize the website content to align with your objectives and target audience. Ensure that the content is engaging, informative, and properly formatted for search engine optimization (SEO) purposes.",
    },
    {
        heading: "Testing and debugging:",
        content:
            "Thoroughly test the website across different browsers, devices, and screen sizes to ensure it functions properly and displays correctly. Identify and fix any bugs, broken links, or performance issues.",
    },
    {
        heading: "Launch:",
        content:
            "Once the website is fully tested and approved, it's time to deploy it to a web server and make it accessible to the public. Configure domain and hosting settings, set up SSL certificates for secure connections, and ensure proper backup mechanisms are in place.",
    },
    {
        heading: "Website maintenance and updates:",
        content:
            "Regularly monitor and maintain your website by applying security updates, fixing any issues that arise, and keeping the content fresh and up to date. This includes updating plugins, themes, and any other components used in the website.",
    },
    {
        heading: "Drive traffic and analyze performance:",
        content:
            "Implement marketing strategies such as search engine optimization (SEO), social media marketing, and content marketing to attract visitors to your website. Analyze website performance using tools like Google Analytics to track user behavior, conversions, and make data-driven improvements.",
    },
];

var features = [
    {
        heading: "Responsive Design:",
        content:
            "A responsive website adapts to different screen sizes and devices, ensuring an optimal user experience on desktops, laptops, tablets, and smartphones.",
    },
    {
        heading: "User-Friendly Interface:",
        content:
            "The website should have an intuitive and user-friendly interface, making it easy for visitors to navigate, find information, and interact with the site.",
    },
    {
        heading: "Clear Navigation:",
        content:
            "A well-structured and organized navigation menu helps users find their way around the website, accessing different pages and sections without confusion.",
    },
    {
        heading: "Compelling Visual Design:",
        content:
            "The website should have an attractive and visually appealing design that aligns with the brand identity and captures the attention of visitors. It includes the use of color schemes, typography, images, and multimedia elements.",
    },
    {
        heading: "Engaging Content:",
        content:
            "High-quality, informative, and engaging content is crucial for a website. It should be well-written, easy to read, and relevant to the target audience, providing value and encouraging visitors to stay and explore further.",
    },
    {
        heading: "Interactive Elements:",
        content:
            "Incorporating interactive elements like forms, surveys, quizzes, comment sections, social media integration, and user-generated content encourages visitor engagement, participation, and interaction with the website.",
    },
    {
        heading: "Fast Load Times:",
        content:
            "Optimizing the website's performance and ensuring fast load times is essential for user experience and search engine optimization (SEO). This can be achieved through techniques like image compression, code optimization, caching, and efficient hosting.",
    },
];

const MicrosoftConsulting = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Microsoft Consulting",
                    desc: "Magnify business numbers with Microsoft technologies. Leverage versatility of Microsoft products for skyrocket business growth"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Microsoft Consulting",
                        desc: "Was the global desktop windows market share in 2018. Microsoft has leveraged subtle marketing strategies that have helped them implant a Windows-based ecosystem overlapping the world map. Since 1995, the company has deployed Dot net-based based operations for windows based application software development. They have offered some of the world’s most pronounced and widely used digital products. The company has built a very robust approach to capture the market with direct customer reach like a complimentary self-reliant system. S2 Integrators is crafting business solutions with Microsoft technologies with Microsoft Exchange, Microsoft Office 365, and Microsoft Dynamics. Practice real-time agile development with need-based scalability of Microsoft technology solutions.",
                        image: "https://img.freepik.com/free-photo/spreadsheet-document-financal-report-concept_53876-132323.jpg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "SAP Ariba Buying",
                            "SAP Ariba Sourcing",
                            "SAP Ariba Contracts",
                            "Ariba Network",
                            "SAP Ariba Supplier Risk",
                            "SAP Ariba Spend Analysis"
                        ]
                    },
                    sessionTwo: {
                        title: "S2 Microsoft technology services",
                        desc: "S2 Microsoft technology services are fortifying lean, mean, and highly scalable business solutions. They are centered on the best possible use of technology and a penetrating direct business strategy spearhead. We power rapid digital businesses with completely enhanced customer experiences that are leveraging the right Microsoft products. Build fast working productivity boosters for beneficiaries and significant business stakeholders. Stumble into a higher trajectory of growth with great solutions for CRM, supply chain, core development, and business process management (BPM) with Microsoft technologies.",
                        image: "https://img.freepik.com/free-photo/side-view-young-attractive-businessman-studying-charts-computer-while-working-home_662251-847.jpg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }

                }}
            />

            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Custom Azure solutions",
                        desc: "S2 Integrators offers business infrastructure based on a cloud platform personifying performance and productivity. We offer interrelated services for cloud storage, deployment, and hosting needs under unique requirements. Bring in the Microsoft collaborative applications, development through SharePoint, and many development insights with consulting support. Update, upgrade of the robust infrastructure. Enjoy the Dynamics 365 privileges to digitize business processes with experienced S2 developers and consultants to build the right business solutions. Be assured of digital risks with foolproof security measures and highest development business management standards."
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Microsoft Dot Net development",
                        desc: "S2 Dot-net developers are experienced with MVC framework. You can conceive business enabling, high-quality branded CX across all Microsoft devices. You can exploit the vast market by building tailor-made Microsoft hosted business applications for more significant engagements. We are capable of delivering complex requirements under Dot-net framework within optimized delivery time and confined cost constraints."
                    },
                }}
            />

            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Microsoft Office 365",
                        desc: "S2 consultants empower you for scalable and time-saving use of specialized resources with Microsoft office 365. You can avail our unique services of highly useful but lesser-known products like Microsoft Publisher, Microsoft Access, Skype, InfoPath and feature-rich applications for the mobile/convertible use. Our developers help you leverage the user-friendliness of consistently evolving Microsoft office applications. Make proper use of a maximized heavy-impact on productivity, profitability, and precision in day-to-day business tasks."
                    },
                }}
            />
            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Collaborated Microsoft CMS service as SharePoint",
                        desc: "You can enjoy super secured, multi-stakeholder, and seamlessly integrated collaboration capabilities bundled in a Microsoft system. We help you configure and customize acquired SharePoint capabilities in your day-to-day business communication, broadcasting groups with media file sharing. It also lets you implement processes in a protected, public, private, and role-wise secured environment."
                    },
                }}
            />
            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Microsoft teams & project management",
                        desc: "Harness the real power of LinkedIn, combined with Microsoft 365. It is in highly affordable but utility-based service offered by talented Microsoft consultants at S2 Integrators. S2 offers new advancements in business, tools, sales pipelines that let you pick new leads with a highly integrated LinkedIn system. It provides very user-friendly and highly effective resources to carry forward business strategies and implement sales all in a highly utilizable platform. Our team of solution experts and tech-business consultant can help you execute a highly productive stack. The stack includes client software, server software, and services belonging to the Microsoft Office family. You can build an easy-to-use, highly effective CRM–cum-ERP solution that seamlessly integrates with every Windows-powered machine bypassing productivity, location, and collaboration constraints."
                    },
                }}
            />

            <ProcessAndFeatures
                data={{
                    unique: {
                        title: "Why S2 Integrators for Microsoft technologies?",
                        desc: "S2 is highly capable of helping you cut down on unnecessary technology resources and cut-down on the highly useful super active Microsoft technologies. Our business-driven resources jet stream sales, on-going revenue generation for an all-round exception technology use. Get ready and let Microsoft technologies ultimately refine activity through the lens of futuristic and excellent present-day prospects in a multidimensional space. Microsoft has transformed the use of computers at home and technology in business forever. Read more about the great use of Microsoft technologies that promote satisfaction and supremacy."
                    },
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default MicrosoftConsulting