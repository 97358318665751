import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "",
        content:
            "At S2Integrators, we understand that data analytics are not just limited to the business world alone. That’s why we think outside the box and develop tools using artificial intelligence (AI) to make them more useful for scientists and researchers as well.",
    },
    {
        heading: "",
        content:
            "We also use machine learning (ML), which helps machines learn and understand what people like and their needs for the business.",
    },
    {
        heading: "",
        content:
            "Our team of dedicated data scientists has deep knowledge and experience in designing and integrating AI to make the best tool for your business's data analytics.",
    },
    {
        heading: "",
        content:
            "Experts at S2Integrators use cutting-edge AI algorithms to enable the machines to learn better and get accurate output so that the data is more insightful.",
    },
    {
        heading: "",
        content:
            "This technology might sound like a complex one, but it has an impact on our daily lives. Just like businesses use data analytics to serve their customers better, scientists use it to predict and understand the behavior of natural and unnatural life, apps understand our needs, and so on.",
    },
    {
        heading: "",
        content:
            "At S2Integrators, our team of data analytics tool development experts listens to and understands your business first and tries to get into details so that we can develop the best data analytics tools that take your business to new heights.",
    },
];
const DataAnalytics = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Data Analytics",
                    desc: "Data analytics (DA) is the process of examining data sets to conclude the information they contain, increasingly with specialized systems and software"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Data Analytics",
                        desc: "We use Artificial Intelligence algorithms to help machines learn themselves from the data they are exposed to and arrive at conclusions, simulating human intelligence. Our data scientists have profound knowledge and experience in designing, implementing and integrating artificial intelligence applications specific to customers' businesses. Want to build intelligent apps with machine learning technology? Machine Learning App Development can make any mobile application more responsive and thoughtful toward user needs.",
                        image: "https://img.freepik.com/premium-photo/businessman-working-with-business-analytics-data-management-system-computer-online-document-management-metrics-connected-database-corporate-strategy-finance-operations-salesx9_661047-4229.jpg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Understanding Requirements",
                            "Design and Development",
                            "Integration",
                            "Testing and launch",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "Data Analytics",
                        desc: "We use Artificial Intelligence algorithms to help machines learn themselves from the data they are exposed to and arrive at conclusions, simulating human intelligence. Our data scientists have profound knowledge and experience in designing, implementing and integrating artificial intelligence applications specific to customers' businesses. Want to build intelligent apps with machine learning technology? Machine Learning App Development can make any mobile application more responsive and thoughtful toward user needs.",
                        image: "https://img.freepik.com/premium-photo/businessman-working-with-business-analytics-data-management-system-computer-online-document-management-metrics-connected-database-corporate-strategy-finance-operations-salesx9_661047-3541.jpg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "",
                        desc: "",
                        data: process,
                        enddesc: ""
                    },
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default DataAnalytics