import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Customized Solutions:",
        content:
            "We understands clearly that every business is unique in its own way. That’s why we try to understand the function of your business before creating an AI tool for it. We create AI solutions based on your needs, such as making your regular tasks automatic, understanding your customer behavior, and many more.",
    },
    {
        heading: "Real Results:",
        content:
            "Using AI for your business can be game-changing, and it provides real-time results without any delay. Whether it be chatbots or predictions, AI can help you out in fractions of seconds. We has many years of proven experience in turning your AI concepts into reality. We believe that our team of experts in AI can help you in your transformation journey.",
    },
    {
        heading: "Easy Integration:",
        content:
            "As a leading AI and ML company, we believe that complete AI integration is necessary, and we do not only provide the AI product; we also make sure that it works and fits in your existing systems. S2Integrators gives complete control of the AI system to its clients, and we provide details and necessary training with regular support.",
    },
];

var features = [
    {
        heading: "",
        content:
            "Our team also keeps track of the latest trends and algorithm updates to ensure our client's websites align with the latest best practices. We also use various SEO tools like Ahrefs, SEMrush, Google Analytics, and Moz to measure the effectiveness of our efforts and make data-driven decisions. We aim to help our clients increase organic traffic, improve their search engine rankings, and drive more qualified leads to their websites. With our expert SEO services, you can rest assured that your website will be optimized for the search engines and your business will be well-positioned for long-term success. Contact us today to learn more about how we can help you achieve your SEO goals.",
    },
];
const DigitalMarketing = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "Digital Marketing",
                    desc: "In the present digital age, digital advertising plays a crucial role. As people depend more on the web to purchase or avail of a service, having your business an enhanced presence in search engines and social media is essential"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "Digital Marketing",
                        desc: "In today's digital age, a solid online presence is essential for businesses to succeed. At S2Integrators, we understand the importance of reaching your target audience through the proper channels and delivering a consistent message across all platforms. That's why we offer a full range of digital marketing services designed to help businesses increase their online visibility, reach their target audience, and grow their brand. Our team of experienced digital marketers will work with you to develop a customized digital marketing strategy that aligns with your business goals and objectives. We provide a full suite of services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, email marketing, and content marketing.",
                        image: "https://img.freepik.com/free-photo/digital-marketing-with-icons-business-people_53876-94833.jpg",
                        // image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "Understanding Requirements",
                            "Design and Development",
                            "Integration",
                            "Testing and launch",
                            "Maintenance and Support"
                        ]
                    },
                    sessionTwo: {
                        title: "Digital Marketing",
                        desc: "We understand that every business is unique, and we tailor our digital marketing solutions to meet the specific needs of each of our clients. We aim to help companies to stand out in a crowded digital space and effectively reach their target audience. Our services include Search Engine Optimization (SEO), Pay-Per-Click (PPC) Advertising, Social Media Marketing, Email Marketing and Content Marketing. Let us help you take your online presence to the next level with a comprehensive digital marketing strategy. Contact us today to learn more about our services and how we can help you grow your business.",
                        image: "https://img.freepik.com/premium-photo/hands-using-mobile-payments_34200-362.jpg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }
                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Search Engine Optimization",
                        desc: "",
                        data: [],
                        enddesc: "At S2Integrators, we understand the importance of search engine optimization (SEO) in today's digital landscape. Our team of experts specializes in providing comprehensive SEO services that help businesses improve their online visibility and reach their target audience. We start by thoroughly analyzing your website and identifying areas for improvement. From there, we develop and implement a customized SEO strategy that includes tactics such as keyword research, on-page optimization, link building, and content creation. Our team of experts will start by conducting a comprehensive website audit to determine the strengths and weaknesses of your website's current ranking. We will then create a customized SEO plan considering your business goals, target audience, and competition."
                    },
                    features: {
                        title: "",
                        desc: "",
                        data: features,
                        enddesc: ""
                    },
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default DigitalMarketing