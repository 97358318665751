import React from 'react'
import Banner from '../services/compnents/Banner'

const HanaCloud = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "S/4HANA Cloud",
                    desc: "Our Intelligent Supplier Network (ISN) provides you the essential digital connection between buyers & suppliers for automated data-rich, fast and accurate transactions"
                }}
            />

            <section className="elementor-section elementor-top-section elementor-element elementor-element-cc9bd01 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-062c276">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-5db734c elementor-widget elementor-widget-softconic_process">
                                <div className="elementor-widget-container">
                                    <div className="section-title-4 text-center two margin-top-20">
                                        <h3 className='text-start'>
                                            The Intelligent Enterprise starts at the digital core
                                        </h3>
                                        <h3 className='text-start'>
                                            Reasons to chose SAP S/4HANA as the digital core for the Intelligent Enterprise:
                                        </h3>

                                        <p className='text-start margin-top-20'>
                                            1. We are a trusted partner to help you navigate any change and provide the flexibility you need.
                                        </p>
                                        <p className='text-start'>
                                            2. We support our customers’ transformation – from every line of business and every industry.
                                        </p>
                                        <p className='text-start'>
                                            3. With the digital core as the backbone of your business, you can unlock new opportunities for innovation and growth.
                                        </p>
                                        <p className='text-start'>
                                            4. We assist your move to the cloud by giving you complete, consistent choice.
                                        </p>
                                        <p className='text-start'>
                                            5. We empower you to keep your promise to your customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HanaCloud