import React from 'react'
import Banner from '../compnents/Banner'
import ServiceContent from '../compnents/ServiceContent'
import ProcessAndFeatures from '../compnents/ProcessAndFeatures'
import OurCoreFeautres from '../compnents/OurCoreFeautres'
import HowWeWork from '../compnents/HowWeWork'

var process = [
    {
        heading: "Define your goals and objectives:",
        content:
            "Determine the purpose of your website and what you want to achieve with it. Identify your target audience, key features, and functionality requirements.",
    },
    {
        heading: "Plan and research:",
        content:
            "Conduct thorough research on your target audience, competitors, and industry trends. Create a sitemap to outline the structure and navigation of your website. Plan the content and gather any necessary resources such as images, videos, and text.",
    },
    {
        heading: "Choose a development platform:",
        content:
            "Select a suitable platform for building your website. Some popular options include WordPress, Drupal, Joomla, Shopify, or custom development using HTML, CSS, and JavaScript.",
    },
    {
        heading: "Design the user interface (UI):",
        content:
            "Create wireframes or mockups to visualize the layout and design of your website. Consider the user experience (UX) by ensuring intuitive navigation, responsive design for different devices, and accessibility for all users.",
    },
    {
        heading: "Develop the website:",
        content:
            "Start implementing the design by coding the website using the chosen platform or programming languages. This involves writing HTML/CSS for the structure and styling, JavaScript for interactivity, and backend development for dynamic functionality such as forms, databases, and content management systems (CMS).",
    },
    {
        heading: "Content creation:",
        content:
            "Write and optimize the website content to align with your objectives and target audience. Ensure that the content is engaging, informative, and properly formatted for search engine optimization (SEO) purposes.",
    },
    {
        heading: "Testing and debugging:",
        content:
            "Thoroughly test the website across different browsers, devices, and screen sizes to ensure it functions properly and displays correctly. Identify and fix any bugs, broken links, or performance issues.",
    },
    {
        heading: "Launch:",
        content:
            "Once the website is fully tested and approved, it's time to deploy it to a web server and make it accessible to the public. Configure domain and hosting settings, set up SSL certificates for secure connections, and ensure proper backup mechanisms are in place.",
    },
    {
        heading: "Website maintenance and updates:",
        content:
            "Regularly monitor and maintain your website by applying security updates, fixing any issues that arise, and keeping the content fresh and up to date. This includes updating plugins, themes, and any other components used in the website.",
    },
    {
        heading: "Drive traffic and analyze performance:",
        content:
            "Implement marketing strategies such as search engine optimization (SEO), social media marketing, and content marketing to attract visitors to your website. Analyze website performance using tools like Google Analytics to track user behavior, conversions, and make data-driven improvements.",
    },
];

var features = [
    {
        heading: "Responsive Design:",
        content:
            "A responsive website adapts to different screen sizes and devices, ensuring an optimal user experience on desktops, laptops, tablets, and smartphones.",
    },
    {
        heading: "User-Friendly Interface:",
        content:
            "The website should have an intuitive and user-friendly interface, making it easy for visitors to navigate, find information, and interact with the site.",
    },
    {
        heading: "Clear Navigation:",
        content:
            "A well-structured and organized navigation menu helps users find their way around the website, accessing different pages and sections without confusion.",
    },
    {
        heading: "Compelling Visual Design:",
        content:
            "The website should have an attractive and visually appealing design that aligns with the brand identity and captures the attention of visitors. It includes the use of color schemes, typography, images, and multimedia elements.",
    },
    {
        heading: "Engaging Content:",
        content:
            "High-quality, informative, and engaging content is crucial for a website. It should be well-written, easy to read, and relevant to the target audience, providing value and encouraging visitors to stay and explore further.",
    },
    {
        heading: "Interactive Elements:",
        content:
            "Incorporating interactive elements like forms, surveys, quizzes, comment sections, social media integration, and user-generated content encourages visitor engagement, participation, and interaction with the website.",
    },
    {
        heading: "Fast Load Times:",
        content:
            "Optimizing the website's performance and ensuring fast load times is essential for user experience and search engine optimization (SEO). This can be achieved through techniques like image compression, code optimization, caching, and efficient hosting.",
    },
];

const SapAriba = () => {
    return (
        <div>
            <Banner
                data={{
                    bgImage: "/wp-content/uploads/elementor/img/serviceimg.png",
                    title: "SAP Ariba",
                    desc: "SAP Ariba combines industry-leading cloud-based applications with the world’s largest Internet-based trading community to help companies discover and collaborate with a global network of partners"
                }}
            />

            <ServiceContent
                data={{
                    sessionOne: {
                        title: "SAP Ariba",
                        desc: "We highly specialized in SAP Intelligent Spend Management solutions and Source-to-Pay end to end transformation. SAP Ariba provides solutions by using latest technologies by overcoming current procurement challenges, streamline procure-to-pay (P2P) processes and improve collaboration with suppliers. We have variety of industry accelerators to speed your SAP Ariba implementation. Our skilled Ariba consultants bring thought leadership, extensive functional, technical and management experience skills to work with the clients to meet their requirements. SAP Ariba Snap is a fast and easy way for your midsize company to implement our integrated procure-to-pay solution. We help small and mid-size businesses by implementing SAP Ariba Snap, which is a fast and easy way to implement integrated procure-to-pay solution.",
                        image: "/wp-content/uploads/elementor/img/ServiceUs2.png",
                        menus: [
                            "SAP Ariba Buying",
                            "SAP Ariba Sourcing",
                            "SAP Ariba Contracts",
                            "Ariba Network",
                            "SAP Ariba Supplier Risk",
                            "SAP Ariba Spend Analysis"
                        ]
                    },
                    sessionTwo: {
                        title: "Our Deep Experience in Source-to-Pay processes",
                        desc: "Our Ariba consultants provide Strategy Development by Identifying savings opportunities, assess market dynamics, and develop an informed sourcing strategy. Supplier Discovery is a new trendy way to find suppliers in this modern technology. Supplier discovery `includes an automated buyer-seller matching tool, helps buyers need instantly connect with high-quality products and suppliers. With the help of current technical tools, digital and cloud network connections we will provide the right sourcing requirements and significant savings. Monitoring and Managing Suppliers and Agreements by Quickly implement supplier agreements, track, and realize savings, and manage supplier performance. Keep supplier information updated and accurate, managing all your data on a single, web-based platform.",
                        image: "/assets/images/services/sap-ariba-01.jpeg"
                        // image: "/wp-content/uploads/elementor/img/ServiceUs.png"
                    }

                }}
            />

            <ProcessAndFeatures
                data={{
                    process: {
                        title: "Process Of Creating A Website",
                        desc: "Here is an overview of the typical process followed by software companies in creating a website",
                        data: process,
                        enddesc: "It's important to note that the website development process can vary depending on the specific project, client needs, and software company practices. However, the steps mentioned above provide a general overview of the typical process followed by software companies in creating a website."
                    },
                    unique: {
                        title: "Why Ariba?",
                        desc: "The SAP Ariba as a very powerful marketplace and trading platform connecting buyers and suppliers to provide the right environment to really focus on the strategic elements in procurement. Next important element, that we see on a daily basis, is the automation. Automation is something that drives us every day, and the procurement function today is probably having strategic tasks, operational tasks, some tactical activities. What automation does, it is fairly simple: It is eliminating the operational, administrative task as well as some tactical tasks. And by that the procurement function might become a little bit smaller but very powerful. The most important part which Ariba focuses is the strategic side – by adding new functions like data architects or data scientists to the function and automating all the rest. The automation does not stop in the procurement function. It really goes further. It is the operational side of procurement, so the purchase order creation and the entire transactional process behind that. But it is also one thing more. It is really looking into the finance side. When the supplier receives a purchase order, sends it back with an invoice, it is only a file that gets sent back. And basically, it gets paid automatically as soon as a goods receipt is in the system. This is a very powerful information and automation."
                    },
                    features: {
                        title: "Features",
                        desc: "Here are some common features of web development for software companies",
                        data: features
                    }
                }}
            />

            <HowWeWork />

            <OurCoreFeautres />


        </div>
    )
}

export default SapAriba